import React, { useState } from 'react'
import axios from 'axios';
import { Helmet } from 'react-helmet';
import AllTestimonials from './all_testimonials';
import { useAuth } from '../../components/utils/loggedin';
import { useEffect } from 'react';

export default function Testimonials() {
  const { token } = useAuth();
  const [testimonials, setTestimonials] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const fetchTestimonial = async (page = 1) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/testimonial/admin/getall?page=${page}&limit=${limit}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Signature: `${process.env.REACT_APP_API_SIGNATURE}`,
        }
      });
      setTestimonials(res.data.testimonials);
      setCurrentPage(page);
      setTotalPages(res?.data?.pagination.totalPages);
    } catch (error) {
      console.error('Error fetching testimonials:', error);
    }
  }


  return (
    <>
      <Helmet>
        <title>Manage Testimonials - BharateClinics</title>
      </Helmet>

      <AllTestimonials />

    </>
  )
}
