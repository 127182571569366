import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../components/utils/loggedin';

export default function DoctorAdmin() {
    const { token } = useAuth();
    const [doctors, setDoctors] = useState(0);
    const [verified, setVerified] = useState(0);
    const [onboarded, setOnboarded] = useState(0);
    const [notVerified, setNotVerified] = useState(0);
    const [notOnboarded, setNotOnboarded] = useState(0);
    const [loading, setLoading] = useState(true);

    const fetchDoctorData = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/doctor/getdoctorstatistics`, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Signature": process.env.REACT_APP_API_SIGNATURE
            }
        })
            .then(response => {
                const totals = response.data.totals;

                setDoctors(totals.totalDoctors);
                setVerified(totals.totalVerified);
                setOnboarded(totals.totalOnboarded);
                setNotVerified(totals.totalNotVerified);
                setNotOnboarded(totals.totalNotOnboarded);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching doctors:', error);
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchDoctorData();
    }, []);

    return (
        <div className="row g-6">
            <div className="col-sm-6 col-lg-3">
                <div className="card card-border-shadow-primary h-100">
                    <div className="card-body">
                        <div className="d-flex align-items-center mb-2">
                            <div className="avatar me-4">
                                <span className="avatar-initial rounded-3 bg-label-success">
                                    <i className="ri-handbag-line ri-24px"></i>
                                </span>
                            </div>
                            <h4 className="mb-0">
                                {loading ? <p>Loading...</p> : doctors}
                            </h4>
                        </div>
                        <h6 className="mb-0 fw-normal">Total Doctors</h6>
                    </div>
                </div>
            </div>

            <div className="col-sm-6 col-lg-3">
                <div className="card card-border-shadow-primary h-100">
                    <div className="card-body">
                        <div className="d-flex align-items-center mb-2">
                            <div className="avatar me-4">
                                <span className="avatar-initial rounded-3 bg-label-success">
                                    <i className="ri-handbag-line ri-24px"></i>
                                </span>
                            </div>
                            <h4 className="mb-0">
                                {loading ? <p>Loading...</p> : verified}
                            </h4>
                        </div>
                        <h6 className="mb-0 fw-normal">Total Verified Doctors</h6>
                    </div>
                </div>
            </div>

            <div className="col-sm-6 col-lg-3">
                <div className="card card-border-shadow-primary h-100">
                    <div className="card-body">
                        <div className="d-flex align-items-center mb-2">
                            <div className="avatar me-4">
                                <span className="avatar-initial rounded-3 bg-label-success">
                                    <i className="ri-handbag-line ri-24px"></i>
                                </span>
                            </div>
                            <h4 className="mb-0">
                                {loading ? <p>Loading...</p> : onboarded}
                            </h4>
                        </div>
                        <h6 className="mb-0 fw-normal">Total Onboarded Doctors</h6>
                    </div>
                </div>
            </div>

            <div className="col-sm-6 col-lg-3">
                <div className="card card-border-shadow-primary h-100">
                    <div className="card-body">
                        <div className="d-flex align-items-center mb-2">
                            <div className="avatar me-4">
                                <span className="avatar-initial rounded-3 bg-label-danger">
                                    <i className="ri-error-warning-line ri-24px"></i>
                                </span>
                            </div>
                            <h4 className="mb-0">
                                {loading ? <p>Loading...</p> : notVerified}
                            </h4>
                        </div>
                        <h6 className="mb-0 fw-normal">Total Not Verified Doctors</h6>
                    </div>
                </div>
            </div>

            <div className="col-sm-6 col-lg-3">
                <div className="card card-border-shadow-primary h-100">
                    <div className="card-body">
                        <div className="d-flex align-items-center mb-2">
                            <div className="avatar me-4">
                                <span className="avatar-initial rounded-3 bg-label-warning">
                                    <i className="ri-error-warning-line ri-24px"></i>
                                </span>
                            </div>
                            <h4 className="mb-0">
                                {loading ? <p>Loading...</p> : notOnboarded}
                            </h4>
                        </div>
                        <h6 className="mb-0 fw-normal">Total Not Onboarded Doctors</h6>
                    </div>
                </div>
            </div>
        </div>
    );
}
