import { Link } from "react-router-dom";

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "../../components/utils/loggedin";
import AllPatient from "./allpatient";
import { Helmet } from "react-helmet";

export default function Patient() {
  const [patient, setPatient] = useState([]);
  const [loading, setLoading] = useState(true);
  const [appointments, setAppointments] = useState([]);
  const [error, setError] = useState(null);
  const { token } = useAuth();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/user/getallusers`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Signature: process.env.REACT_APP_API_SIGNATURE,
        },
      })
      .then((response) => {
        console.log(response.data.data);
        setPatient(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching admins:", error);
        setLoading(false);
      });
  }, []);

  // appointments of patient
  // useEffect(() => {
  //   const fetchAppointments = async () => {
  //     try {
  //       const response = await axios.get(
  //         `${process.env.REACT_APP_API_URL}/appointments/user`,
  //         {
  //           headers: {
  //             Signature: process.env.REACT_APP_API_SIGNATURE,
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       );
  //       setAppointments(response.data);
  //     } catch (err) {
  //       setError("Error fetching appointments");
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchAppointments();
  // }, [token]);
  return (
    <>
      <Helmet>
        <title>Manage Patient Page</title>
      </Helmet>
      <div className="row g-6 mb-6">
        <div className="col-sm-6 col-xl-3">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between">
                <div className="me-1">
                  <p className="text-heading mb-1">Total Patients</p>
                  <div className="d-flex align-items-center">
                    <h4 className="mb-1 me-2">{patient.length}</h4>
                  </div>
                </div>
                <div className="avatar">
                  <div className="avatar-initial bg-label-primary rounded-3">
                    <div className="ri-user-line ri-26px" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-xl-3">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between">
                <div className="me-1">
                  <p className="text-heading mb-1"> Scheduled Appointments</p>
                  <div className="d-flex align-items-center">
                    <h4 className="mb-1 me-1">
                      {appointments.filter(appointment => appointment.status === 'scheduled').length}
                    </h4>
                  </div>
                </div>
                <div className="avatar">
                  <div className="avatar-initial bg-label-info rounded-3">
                    <div className="ri-calendar-line ri-26px" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-xl-3">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between">
                <div className="me-1">
                  <p className="text-heading mb-1">Appointments Fixed</p>
                  <div className="d-flex align-items-center">
                    <h4 className="mb-1 me-1">
                      {appointments.filter(appointment => appointment.status === 'fixed').length}
                    </h4>
                  </div>
                </div>
                <div className="avatar">
                  <div className="avatar-initial bg-label-success rounded-3">
                    <div className="ri-check-line ri-26px" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Users List Table */}
      <div className="card">
        <div className="card-header border-bottom">
          <h5 className="card-title mb-0">Patient</h5>
        </div>
        <div className="card-datatable table-responsive">
          <div
            id="DataTables_Table_0_wrapper"
            className="dataTables_wrapper dt-bootstrap5 no-footer"
          >

            <AllPatient patients={patient} loading={loading} />
          </div>
        </div>
      </div>
    </>
  );
}
