import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "../../components/utils/loggedin";

export default function AllDoctors() {
  const [doctors, setDoctors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchPhone, setSearchPhone] = useState("");
  const [searchName, setSearchName] = useState("");
  const [searchEmail, setSearchEmail] = useState("");
  const [searchStatus, setSearchStatus] = useState("");
  const [searchOnboarded, setSearchOnboarded] = useState("");
  const [noData, setNoData] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const doctorsPerPage = 10;
  const { token, admin } = useAuth();
  const navigate = useNavigate();

  const fetchDoctorData = (page = 1) => {
    setLoading(true); // Ensure loading state is true before fetching
    axios.get(
      `${process.env.REACT_APP_API_URL}/doctor/getdoctorbyadmin?page=${page}&limit=${limit}&phone=${searchPhone}&name=${searchName}&email=${searchEmail}&status=${searchStatus}&onboarded=${searchOnboarded}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Signature: process.env.REACT_APP_API_SIGNATURE,
        },
      }
    )
      .then(response => {
        if (response.data.doctors.length === 0) {
          setNoData(true);
        } else {
          setNoData(false);
        }
        setDoctors(response.data.doctors);
        setLoading(false);
        setCurrentPage(page);
        setTotalPages(response?.data?.pagination?.totalPages || 1);
        setTotalItems(response?.data?.pagination?.totalItems || 0); // Ensure totalPages is set correctly
      })
      .catch(error => {
        console.error('Error fetching doctors:', error);
        setLoading(false);
        setNoData(true); // Set noData to true on error
      });
  };

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    fetchDoctorData(page);
  };

  const renderPagination = () => {
    if (totalItems <= limit) return null; // No need for pagination if total items are less than or equal to the limit

    const totalPages = Math.ceil(totalItems / limit); // Calculate total pages based on the limit
    const pageNumbers = [];
    const maxPageButtons = 5; // The number of visible page buttons
    let startPage = Math.max(currentPage - Math.floor(maxPageButtons / 2), 1);
    let endPage = Math.min(startPage + maxPageButtons - 1, totalPages);

    if (endPage - startPage < maxPageButtons - 1) {
      startPage = Math.max(endPage - maxPageButtons + 1, 1);
    }

    // Generate page number buttons
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
          <button className="page-link" onClick={() => handlePageChange(i)}>
            {i}
          </button>
        </li>
      );
    }

    return (
      <ul className="pagination">
        {/* Previous button */}
        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
          <button
            className="page-link"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <i className="tf-icon ri-arrow-left-s-line ri-20px" />
          </button>
        </li>

        {/* Display first page and ellipsis if necessary */}
        {startPage > 1 && (
          <>
            <li className="page-item">
              <button className="page-link" onClick={() => handlePageChange(1)}>
                1
              </button>
            </li>
            {startPage > 2 && (
              <li className="page-item disabled">
                <span className="page-link">...</span>
              </li>
            )}
          </>
        )}

        {/* Page numbers */}
        {pageNumbers}

        {/* Display last page and ellipsis if necessary */}
        {endPage < totalPages && (
          <>
            {endPage < totalPages - 1 && (
              <li className="page-item disabled">
                <span className="page-link">...</span>
              </li>
            )}
            <li className="page-item">
              <button className="page-link" onClick={() => handlePageChange(totalPages)}>
                {totalPages}
              </button>
            </li>
          </>
        )}

        {/* Next button */}
        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
          <button
            className="page-link"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            <i className="tf-icon ri-arrow-right-s-line ri-20px" />
          </button>
        </li>
      </ul>
    );
  };

  function ViewDoctor(doctorId) {
    navigate("/admins/doctors/view", { state: { doctorId } });
  }

  useEffect(() => {
    fetchDoctorData();
  }, [token, limit, searchPhone, searchEmail, searchName, searchOnboarded, searchStatus]);

  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <td colSpan="7">
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <select
                  className="form-select form-select-sm"
                  value={limit}
                  onChange={(e) => setLimit(Number(e.target.value))}
                  style={{ width: '150px' }}
                >
                  <option value={10}>10 per page</option>
                  <option value={20}>20 per page</option>
                  <option value={50}>50 per page</option>
                  <option value={100}>100 per page</option>
                </select>
                {admin?.role?.permissions?.ADMIN_PANEL?.doctor?.add_new_doctor &&
                  <Link
                    to='/doctors/add'
                    className="btn btn-label-primary waves-effect waves-light"
                  >
                    <i className="ri-add-line me-0 me-sm-1 d-inline-block d-sm-none" />
                    <span className="d-none d-sm-inline-block"> Add New Doctor </span>
                  </Link>
                }
              </div>
            </td>
          </tr>
          <tr>
            <th>S.No</th>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Status</th>
            <th>Onboarded</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tr>
          <td></td>
          <td>
            <div className="form-floating form-floating-outline">
              <input
                type="search"
                className="form-control form-control-sm"
                placeholder="Search Name"
                aria-controls="DataTables_Table_0"
                value={searchName}
                onChange={(e) => setSearchName(e.target.value)}
              />
            </div>
          </td>
          <td>
            <div className="form-floating form-floating-outline">
              <input
                type="search"
                className="form-control form-control-sm"
                placeholder="Search Email"
                aria-controls="DataTables_Table_0"
                value={searchEmail}
                onChange={(e) => setSearchEmail(e.target.value)}
              />
            </div>
          </td>
          <td>
            <div className="form-floating form-floating-outline">
              <input
                type="search"
                className="form-control form-control-sm"
                placeholder="Search Phone"
                aria-controls="DataTables_Table_0"
                value={searchPhone}
                onChange={(e) => setSearchPhone(e.target.value)}
              />
            </div>
          </td>
          <td></td>
          <td></td>
        </tr>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="7">Loading...</td>
            </tr>
          ) : noData ? (
            <tr>
              <td colSpan="7" className="text-center text-muted">
                No data found
              </td>
            </tr>
          ) : (
            doctors.map((doctor, index) => (
              <tr key={doctor.id}>
                <td>{(currentPage - 1) * doctorsPerPage + index + 1}</td>
                <td>{doctor.first_name} {doctor.last_name}</td>
                <td>{doctor.email}</td>
                <td>{doctor.phone}</td>
                <td className={doctor.verified ? "text-success" : "text-danger"}>
                  {doctor.verified ? (
                    <span className="badge bg-label-success rounded-pill">Verified</span>
                  ) : (
                    <span className="badge bg-label-danger rounded-pill">Not Verified</span>
                  )}
                </td>
                <td className={doctor.onboarded ? "text-success" : "text-warning"}>
                  {doctor.onboarded ? (
                    <span className="badge bg-label-success rounded-pill">Onboarded</span>
                  ) : (
                    <span className="badge bg-label-warning rounded-pill">Not Onboarded</span>
                  )}
                </td>
                <td>
                  <div className="dropdown">
                    <button
                      type="button"
                      className="btn p-0 dropdown-toggle hide-arrow"
                      data-bs-toggle="dropdown"
                    >
                      <i className="ri-more-2-line" />
                    </button>
                    <div className="dropdown-menu">
                      {admin?.role?.permissions?.ADMIN_PANEL?.doctor?.view_doctor &&
                        <button
                          onClick={() => ViewDoctor(doctor.id)}
                          className="dropdown-item waves-effect"
                        >
                          <i className="ri-eye-line me-2" /> View
                        </button>
                      }
                    </div>
                  </div>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      <div className="row mt-3">
        <nav aria-label="Page navigation">
          {renderPagination()}
        </nav>
      </div>
    </div >
  );
}
