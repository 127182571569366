import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../components/utils/loggedin";
import axios from "axios";
import * as XLSX from 'xlsx';


export default function AllPatient() {
  const navigate = useNavigate();
  const { token, admin } = useAuth();
  const [patient, setPatient] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchName, setSearchName] = useState("")
  const [searchEmail, setSearchEmail] = useState("")
  const [searchPhone, setSearchPhone] = useState("")
  const [searchUsername, setSearchUsername] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const pages = 10;

  const fetchPatient = (page = 1) => {
    setLoading(true)
    axios
      .get(`${process.env.REACT_APP_API_URL}/user/getallusers?page=${page}&limit=${limit}&name=${searchName}&email=${searchEmail}&phone=${searchPhone}&username=${searchUsername}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Signature: process.env.REACT_APP_API_SIGNATURE,
        },
      })
      .then((response) => {
        console.log(response.data.data);
        setPatient(response.data.data);
        setCurrentPage(page);
        setTotalPages(response?.data?.pagination.pageSize);
        setTotalItems(response?.data?.pagination?.totalItems);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching admins:", error);
        setPatient([]);
        setLoading(false);
      });
  };

  const renderPagination = () => {
    if (totalItems <= limit) return null; // No need for pagination if total items are less than or equal to the limit

    const totalPages = Math.ceil(totalItems / limit); // Calculate total pages based on the limit
    const pageNumbers = [];
    const maxPageButtons = 5; // The number of visible page buttons
    let startPage = Math.max(currentPage - Math.floor(maxPageButtons / 2), 1);
    let endPage = Math.min(startPage + maxPageButtons - 1, totalPages);

    if (endPage - startPage < maxPageButtons - 1) {
      startPage = Math.max(endPage - maxPageButtons + 1, 1);
    }

    // Generate page number buttons
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
          <button className="page-link" onClick={() => handlePageChange(i)}>
            {i}
          </button>
        </li>
      );
    }

    return (
      <ul className="pagination">
        {/* Previous button */}
        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
          <button
            className="page-link"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <i className="tf-icon ri-arrow-left-s-line ri-20px" />
          </button>
        </li>

        {/* Display first page and ellipsis if necessary */}
        {startPage > 1 && (
          <>
            <li className="page-item">
              <button className="page-link" onClick={() => handlePageChange(1)}>
                1
              </button>
            </li>
            {startPage > 2 && (
              <li className="page-item disabled">
                <span className="page-link">...</span>
              </li>
            )}
          </>
        )}

        {/* Page numbers */}
        {pageNumbers}

        {/* Display last page and ellipsis if necessary */}
        {endPage < totalPages && (
          <>
            {endPage < totalPages - 1 && (
              <li className="page-item disabled">
                <span className="page-link">...</span>
              </li>
            )}
            <li className="page-item">
              <button className="page-link" onClick={() => handlePageChange(totalPages)}>
                {totalPages}
              </button>
            </li>
          </>
        )}

        {/* Next button */}
        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
          <button
            className="page-link"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            <i className="tf-icon ri-arrow-right-s-line ri-20px" />
          </button>
        </li>
      </ul>
    );
  };


  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    fetchPatient(page);
  };

  useEffect(() => {
    fetchPatient();
  }, [token, limit, searchEmail, searchName, searchPhone, searchUsername])



  function ViewPatient(patientId) {
    navigate("/admins/patient/view", { state: { patientId } });
  }

  // Function to format dates as dd/mm/yy
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = String(date.getFullYear()).slice(-2); // Get last two digits of the year

    return `${day}/${month}/${year}`;
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(patient.map((p, index) => ({
      SNo: index + 1,
      PatientId: p.username || 'BHPTXXXXX',
      Name: `${p.first_name} ${p.last_name}`,
      Email: p.email,
      Phone: p.phone,
      CreatedAt: formatDate(p.created_at),
    })));

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Patients");

    XLSX.writeFile(workbook, 'PatientsData.xlsx');
  };

  return (
    <div>
      {/* Patient Table */}
      <table className="table">
        <thead>
          <tr>
            <td colSpan="7">
              <div className="d-flex justify-content-between align-items-center">
                {/* Dropdown for items per page */}
                <select
                  className="form-select form-select-sm w-auto"
                  value={limit}
                  onChange={(e) => setLimit(e.target.value)}
                >
                  <option value={5}>5 per page</option>
                  <option value={10}>10 per page</option>
                  <option value={15}>15 per page</option>
                  <option value={20}>20 per page</option>
                </select>

                {/* Button for export to Excel */}
                <button className="btn btn-primary ms-2" onClick={exportToExcel}>
                  Export to Excel
                </button>
              </div>
            </td>
          </tr>


          <tr>
            <th style={{ width: "5%" }}>S.No</th>
            <th>Patinet Id</th>
            <th style={{ width: "20%" }}>Name</th>
            <th style={{ width: "20%" }}>Email</th>
            <th style={{ width: "15%" }}>Phone</th>
            <th style={{ width: "10%" }}>Created</th>
            <th style={{ width: "10%" }}>Actions</th>
          </tr>
          <tr>
            <td style={{ width: "5%" }}></td>
            <td>
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="Search by Id"
                value={searchUsername}
                onChange={(e) => setSearchUsername(e.target.value)}
              />
            </td>
            <td style={{ width: "20%" }}>
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="Search by name"
                value={searchName}
                onChange={(e) => setSearchName(e.target.value)}
              />
            </td>
            <td style={{ width: "20%" }}>
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="Search by email"
                value={searchEmail}
                onChange={(e) => setSearchEmail(e.target.value)}
              />
            </td>
            <td style={{ width: "15%" }}>
              {/* <input
                type="text"
                className="form-control form-control-sm"
                placeholder="Search by phone"
                value={searchPhone}
                onChange={(e) => setSearchPhone(e.target.value)}
              /> */}
            </td>
            <td style={{ width: "10%" }}></td>
            <td style={{ width: "10%" }}></td>

          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="7">Loading...</td>
            </tr>
          ) : patient.length === 0 ? (
            <tr>
              <td colSpan="7" className="text-center text-muted">
                No data found
              </td>
            </tr>
          ) : (
            patient.map((patient, index) => (
              <tr key={patient.id}>
                <td>{(currentPage - 1) * pages + index + 1}</td>
                <td>{patient.username ? patient.username : 'BHPTXXXXX'}</td>
                <td>
                  {patient.first_name} {patient.last_name}
                </td>
                <td>{patient.email}</td>
                <td>{patient.phone}</td>
                <td>{formatDate(patient.created_at)}</td>

                <td>
                  {admin?.role?.permissions?.ADMIN_PANEL?.patient?.patient_view && <button
                    className="btn"
                    onClick={() => ViewPatient(patient.id)}
                  >
                    <i className="ri-eye-line"></i>
                  </button>}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      <div className="row mt-3">
        <nav aria-label="Page navigation">
          {renderPagination()}
        </nav>
      </div>
    </div >
  );
}
