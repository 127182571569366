import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import AddRole from './add_role';
import AllRoles from './all_roles';
import axios from 'axios';
import { useAuth } from '../../components/utils/loggedin';
import { permissions } from '../../components/utils/permission';

export default function RolesAndPermissions() {
    const { token } = useAuth();
    const [roles, setRoles] = useState([]);
    const [permissionsState, setPermissionsState] = useState(
        permissions.reduce((acc, permissionCategory) => {
            Object.keys(permissionCategory).forEach((categoryKey) => {
                acc[categoryKey] = permissionCategory[categoryKey];
            });
            return acc;
        }, {})
    );

    const fetchRoles = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/role`, {
                headers: {
                    'Content-Type': 'application/json',
                    Signature: `${process.env.REACT_APP_API_SIGNATURE}`,
                    Authorization: `Bearer ${token}`,
                },
            });
            setRoles(response.data);
        } catch (error) {
            console.error('Error fetching roles:', error);
        }
    };

    useEffect(() => {
        fetchRoles();
    }, []);

    return (
        <>
            <Helmet>
                <title>Roles & Permissions - BharateClinics</title>
            </Helmet>
            {/* Role cards */}
            <div className="row g-6">
                <div className="col-12">
                    {/* Role Table */}
                    <div className="card">
                        <div className="card-datatable table-responsive">
                            <div className="row mx-1 d-flex align-items-center">
                                <div className="p-2 py-4 col-sm-12 col-md-5 d-flex align-items-center justify-content-between justify-content-md-start gap-4 mt-5 mt-md-0">
                                    <h5 className="card-title">Roles & Permissions</h5>
                                </div>
                                <div className="p-3 col-sm-12 col-md-7 text-md-end mt-md-0">
                                    <button
                                        data-bs-target="#addRoleModal"
                                        data-bs-toggle="modal"
                                        className="btn btn-label-primary text-nowrap add-new-role ms-md-auto"
                                    >
                                        <i className="ri-add-line me-2"></i> Add Role
                                    </button>
                                </div>
                            </div>
                            <AllRoles fetchRoles={fetchRoles} roles={roles} />
                        </div>
                    </div>
                    {/* /Role Table */}
                </div>
            </div>
            {/* /Role cards */}
            {/* Add Role Modal */}
            <AddRole fetchRoles={fetchRoles} permissionsState={permissionsState} />
            {/* /Add Role Modal */}
        </>
    );
}
