import axios from "axios";
import React, { useState } from "react";
import { useAuth } from "../../../../components/utils/loggedin";
import { toast } from "react-toastify";

export default function ClinicPasswordForm({ clinic }) {
    const [formData, setFormData] = useState({
        newPassword: "",
        confirmPassword: "",
    });
    const { token } = useAuth();

    const [errors, setErrors] = useState({
        newPassword: "",
        confirmPassword: "",
    });
    const [success, setSuccess] = useState("");
    const [apiError, setApiError] = useState("");

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const validate = () => {
        const newErrors = {};
        if (formData.newPassword.length < 8) {
            newErrors.newPassword = "Password must be at least 8 characters long.";
        }
        if (formData.newPassword !== formData.confirmPassword) {
            newErrors.confirmPassword = "Passwords do not match.";
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validate()) {
            return;
        }
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_API_URL}/clinic/reset-password`, // Ensure this endpoint is correct
                {
                    id: clinic.id,
                    password: formData.newPassword,
                },
                {
                    headers: {
                        Signature: process.env.REACT_APP_API_SIGNATURE,
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            toast.success("Password changed successfully");
            setApiError("");
            setSuccess("Password changed successfully");
            setFormData({ newPassword: "", confirmPassword: "" });
        } catch (error) {
            setApiError(error.response?.data?.message || "An error occurred.");
            setSuccess("");
        }
    };

    return (
        <div className="card mb-6">
            <h5 className="card-header">Change Password</h5>
            <div className="card-body">
                <form onSubmit={handleSubmit}>
                    <div className="alert alert-warning alert-dismissible" role="alert">
                        <h5 className="alert-heading mb-1">
                            Ensure that these requirements are met
                        </h5>
                        <span>Minimum 8 characters long, uppercase & symbol</span>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="alert"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="row gx-5">
                        <div className="mb-3 col-12 col-sm-6 form-password-toggle">
                            <div className="input-group input-group-merge">
                                <div className="form-floating form-floating-outline">
                                    <input
                                        type="password"
                                        id="newPassword"
                                        name="newPassword"
                                        className={`form-control ${errors.newPassword ? "is-invalid" : ""
                                            }`}
                                        placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                                        value={formData.newPassword}
                                        onChange={handleChange}
                                        required
                                    />
                                    <label htmlFor="newPassword">New Password</label>
                                    <div className="invalid-feedback">{errors.newPassword}</div>
                                </div>
                                {/*  {/* <span className="input-group-text cursor-pointer text-heading">
                                    <i className="ri-eye-off-line"></i>
                                </span> */}
                            </div>
                        </div>

                        <div className="mb-3 col-12 col-sm-6 form-password-toggle">
                            <div className="input-group input-group-merge">
                                <div className="form-floating form-floating-outline">
                                    <input
                                        type="password"
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        className={`form-control ${errors.confirmPassword ? "is-invalid" : ""
                                            }`}
                                        placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                                        value={formData.confirmPassword}
                                        onChange={handleChange}
                                        required
                                    />
                                    <label htmlFor="confirmPassword">Confirm New Password</label>
                                    <div className="invalid-feedback">
                                        {errors.confirmPassword}
                                    </div>
                                </div>
                                {/* <span className="input-group-text cursor-pointer text-heading">
                                    <i className="ri-eye-off-line"></i>
                                </span> */}
                            </div>
                        </div>

                        <div>
                            <button type="submit" className="btn btn-primary me-2">
                                Change Password
                            </button>
                        </div>
                    </div>
                    {success && <div className="mt-3 alert alert-success">{success}</div>}
                    {apiError && (
                        <div className="mt-3 alert alert-danger">{apiError}</div>
                    )}
                </form>
            </div>
        </div>
    );
}
