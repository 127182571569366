import React from 'react'
import AddContact from './addcontact'
import { useAuth } from '../../../components/utils/loggedin';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';

export default function Contact() {
    const { token } = useAuth();

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalItems, setTotalItems] = useState(0);

    const fetchContacts = async (page = currentPage) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/contact-us?page=${page}&limit=${limit}`, {
                headers: {
                    'Signature': process.env.REACT_APP_API_SIGNATURE,
                    'Authorization': `Bearer ${token}`,
                },
            });

            setCurrentPage(page);
            setTotalPages(response?.data?.pagination?.totalPages); // Set total pages correctly
            setTotalItems(response?.data?.pagination?.totalItems);
        } catch (error) {
            console.error('Error fetching contacts:', error);
            toast.error('Failed to load contacts'); // Show error message
        }
    };

    const renderPagination = () => {
        if (totalItems <= limit) return null; // No need for pagination if total items are less than or equal to the limit

        const totalPages = Math.ceil(totalItems / limit); // Calculate total pages based on the limit
        const pageNumbers = [];
        const maxPageButtons = 5; // The number of visible page buttons
        let startPage = Math.max(currentPage - Math.floor(maxPageButtons / 2), 1);
        let endPage = Math.min(startPage + maxPageButtons - 1, totalPages);

        if (endPage - startPage < maxPageButtons - 1) {
            startPage = Math.max(endPage - maxPageButtons + 1, 1);
        }

        // Generate page number buttons
        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(i)}>
                        {i}
                    </button>
                </li>
            );
        }

        return (

            <ul className="pagination">
                {/* Previous button */}
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button
                        className="page-link"
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        <i className="tf-icon ri-arrow-left-s-line ri-20px" />
                    </button>
                </li>

                {/* Display first page and ellipsis if necessary */}
                {startPage > 1 && (
                    <>
                        <li className="page-item">
                            <button className="page-link" onClick={() => handlePageChange(1)}>
                                1
                            </button>
                        </li>
                        {startPage > 2 && (
                            <li className="page-item disabled">
                                <span className="page-link">...</span>
                            </li>
                        )}
                    </>
                )}

                {/* Page numbers */}
                {pageNumbers}

                {/* Display last page and ellipsis if necessary */}
                {endPage < totalPages && (
                    <>
                        {endPage < totalPages - 1 && (
                            <li className="page-item disabled">
                                <span className="page-link">...</span>
                            </li>
                        )}
                        <li className="page-item">
                            <button className="page-link" onClick={() => handlePageChange(totalPages)}>
                                {totalPages}
                            </button>
                        </li>
                    </>
                )}

                {/* Next button */}
                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button
                        className="page-link"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    >
                        <i className="tf-icon ri-arrow-right-s-line ri-20px" />
                    </button>
                </li>
            </ul>
        );
    };

    const handlePageChange = (page) => {
        if (page < 1 || page > totalPages) return;
        fetchContacts(page);
    };

    useEffect(() => {
        fetchContacts(); // Fetch contacts on initial load
    }, [token]); // Initial load only

    useEffect(() => {
        fetchContacts(); // Refetch contacts when limit changes
    }, [limit]); // Re-fetch when limit changes

    return (
        <>
            <Helmet>
                <title>Manage Enquiry - BharateClinics</title>
            </Helmet>
            <div className="card">
                <div className="card-datatable table-responsive pt-0">
                    <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                        <div className="card-header flex-column flex-md-row border-bottom">
                            <div className="head-label text-center">
                                <h5 className="card-title mb-0">Enquiries</h5>
                            </div>
                            {/* <div className="dt-action-buttons text-end pt-3 pt-md-0">
                            <div className="dt-buttons btn-group flex-wrap">
                                <button
                                    className="btn btn-secondary create-new btn-primary waves-effect waves-light"
                                    tabIndex={0}
                                    aria-controls="DataTables_Table_0"
                                    type="button"
                                    data-bs-target="#offcanvasAddContact"
                                    data-bs-toggle="offcanvas"
                                >
                                    <span>
                                        <i className="ri-add-line ri-16px me-sm-2" />{" "}
                                        <span>Add New Contact</span>
                                    </span>
                                </button>
                            </div>
                        </div> */}
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-6 mt-5 mt-md-0">
                                <div className="dataTables_length" id="DataTables_Table_0_length">
                                    <label>
                                        Show{" "}
                                        <select
                                            name="DataTables_Table_0_length"
                                            aria-controls="DataTables_Table_0"
                                            className="form-select form-select-sm"
                                            value={limit}
                                            onChange={(e) => setLimit(Number(e.target.value))} // Ensure limit is a number
                                        >
                                            <option value={10}>10</option>
                                            <option value={25}>25</option>
                                            <option value={50}>50</option>
                                            <option value={75}>75</option>
                                            <option value={100}>100</option>
                                        </select>{" "}
                                        entries
                                    </label>
                                </div>
                            </div>
                        </div>
                        <AddContact renderPagination={renderPagination} />
                    </div>
                </div>
            </div>
        </>
    );
}
