import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import validator from "validator";
import axios from "axios";
import { useAuth } from "../../components/utils/loggedin";
import Admins from "../admins";
export default function AllSalesReps({ salesreps, loading }) {
    const { token, admin } = useAuth()
    const [startRange, setStartRange] = useState("");
    const [endRange, setEndRange] = useState("");
    const [selectedSalesRep, setSelectedSalesRep] = useState(null);
    const [errors, setErrors] = useState({
        startRange: "",
        endRange: "",
    });
    const navigate = useNavigate();
    function ViewSalesRep(salesrepId) {
        navigate('/sales/view', { state: { salesrepId } })
    }
    console.log(salesreps.id);

    const validateInputs = () => {
        let isValid = true;
        const newErrors = { startRange: "", endRange: "" };
        if (
            !validator.isLength(startRange, { min: 10, max: 10 }) ||
            !validator.isLength(endRange, { min: 10, max: 10 })
        ) {
            newErrors.startRange = "Start Range should be in the format BH00000001";
            newErrors.endRange = "End Range should be in the format BH99999999";
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateInputs()) return;

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/healthcard/salesrep/generatecard/${selectedSalesRep.id}`,
                {
                    start_range: startRange,
                    end_range: endRange,

                },
                {
                    headers: {
                        Signature: process.env.REACT_APP_API_SIGNATURE,
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response.status === 200) {
                toast.success(response.data.message);
                setStartRange("");
                setEndRange("");
                document.getElementById("closeCanvas").click();

            }
        } catch (error) {
            toast.error(error?.response?.data?.message || "Error Generating Cards");
        }
    };

    return (
        <table className="table">
            <thead>
                <tr>
                    <th>S.No</th>
                    <th>User Number</th>
                    <th>Name</th>
                    <th>Phone</th>
                    <th>Status</th>
                    <th>onboarded</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {loading ? (
                    <tr>
                        <td colSpan="8">Loading...</td>
                    </tr>
                ) : (
                    salesreps.map((salesrep, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{salesrep.username ? salesrep.username : "BHSRXXXX"}</td>
                            <td>{salesrep.first_name} {salesrep.last_name}</td>
                            <td>{salesrep.phone}</td>
                            <td className={salesrep.verified ? "text-success" : "text-danger"}>{salesrep.verified ? <span className="badge bg-label-success rounded-pill">Verified</span> : <span className="badge bg-label-danger rounded-pill">Not Verified</span>}</td>
                            <td className={salesrep.onboarded ? "text-success" : "text-warning"}>{salesrep.onboarded ? <span className="badge bg-label-success rounded-pill">onboarded</span> : <span className="badge bg-label-warning rounded-pill">Not onboarded</span>}</td>
                            <td>
                                <div className="dropdown">
                                    <button
                                        type="button"
                                        className="btn p-0 dropdown-toggle hide-arrow"
                                        data-bs-toggle="dropdown"
                                    >
                                        <i className="ri-more-2-line" />
                                    </button>
                                    <div className="dropdown-menu">
                                        {
                                            admin?.role?.permissions?.ADMIN_PANEL?.sales?.view_salesrep &&
                                            <button button onClick={() => ViewSalesRep(salesrep.id)} className="dropdown-item waves-effect" href="javascript:void(0);">
                                                <i className="ri-eye-line me-2" /> View
                                            </button>
                                        }
                                        {
                                            admin?.role?.permissions?.ADMIN_PANEL?.sales?.allotcard_salesrep &&
                                            <button onClick={() => setSelectedSalesRep(salesrep)} className="dropdown-item waves-effect" href="javascript:void(0);" type="button"
                                                data-bs-toggle="modal"
                                                data-bs-target="#addNewCCModal">
                                                <i class="ri-heart-add-line me-2"></i> Allot Card
                                            </button>
                                        }
                                    </div>
                                    <div
                                        className="modal fade"
                                        id="addNewCCModal"
                                        tabIndex={-1}
                                        aria-hidden="true"
                                    >
                                        <div className="modal-dialog modal-dialog-centered1 modal-simple modal-add-new-cc">
                                            <div className="modal-content">
                                                <button
                                                    type="button"
                                                    className="btn-close"
                                                    data-bs-dismiss="modal"
                                                    aria-label="Close"
                                                />
                                                <div className="modal-body p-0">
                                                    <div className="text-center mb-6">
                                                        <h4 className="mb-2">Add New Smart Health Card</h4>
                                                        <p>Add new card to avail benefits</p>
                                                    </div>
                                                    <form
                                                        id="addNewCCForm"
                                                        className="row g-5"
                                                        onSubmit={handleSubmit}
                                                    >
                                                        <div className="col-6">
                                                            <div className="input-group input-group-merge">
                                                                <div className="form-floating form-floating-outline">
                                                                    <input
                                                                        id="startRange"
                                                                        name="startRange"
                                                                        className={`form-control ${errors.startRange ? "is-invalid" : ""}`}
                                                                        type="text"
                                                                        placeholder="Start Range (e.g. BH00000001)"
                                                                        value={startRange}
                                                                        onChange={(e) => setStartRange(e.target.value)}
                                                                        aria-describedby="modalAddCard1"
                                                                    />
                                                                    <label htmlFor="startRange">Start Range</label>
                                                                    {errors.startRange && (
                                                                        <div className="invalid-feedback">
                                                                            {errors.startRange}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-6">
                                                            <div className="input-group input-group-merge">
                                                                <div className="form-floating form-floating-outline">
                                                                    <input
                                                                        id="endRange"
                                                                        name="endRange"
                                                                        className={`form-control ${errors.endRange ? "is-invalid" : ""}`}
                                                                        type="text"
                                                                        placeholder="End Range (e.g. BH99999999)"
                                                                        value={endRange}
                                                                        onChange={(e) => setEndRange(e.target.value)}
                                                                        aria-describedby="modalAddCard2"
                                                                    />
                                                                    <label htmlFor="endRange">End Range</label>
                                                                    {errors.endRange && (
                                                                        <div className="invalid-feedback">
                                                                            {errors.endRange}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 d-flex flex-wrap justify-content-center gap-4 row-gap-4">
                                                            <button type="submit" className="btn btn-primary">
                                                                Submit
                                                            </button>
                                                            <button
                                                                type="reset"
                                                                className="btn btn-outline-secondary btn-reset"
                                                                data-bs-dismiss="modal"
                                                                aria-label="Close"
                                                                id="closeCanvas"
                                                            >
                                                                Cancel
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </td>
                        </tr>
                        // model for the salesreps card allotment
                    ))
                )}
            </tbody>
        </table >


    );
}
