import { useState } from "react";
import EditRole from "./edit_roles";
import DeleteRole from "./delete_role";

export default function AllRoles({ roles, fetchRoles }) {
    const [selectedRole, setSelectedRole] = useState(null);

    const handleRoleSelection = (role) => {
        setSelectedRole(role);
    };

    return (
        <>
            <table className="datatables-users table">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Role</th>
                        <th>Status</th>
                        <th className="text-center">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {roles.map((role, index) => (
                        <tr key={role.id}>
                            <td>{index + 1}</td>
                            <td>{role.name}</td>
                            <td>{role.status}</td>
                            <td className="text-center">
                                <button
                                    onClick={() => handleRoleSelection(role)}
                                    data-bs-target="#editRoleModal"
                                    data-bs-toggle="modal"
                                    className="btn"
                                >
                                    <i className='text-primary tf-icon ri-pencil-line ri-20px'></i>
                                </button>
                                {/* Conditionally render delete button based on role ID */}
                                {!["1", "2", "3"].includes(role.id.toString()) && (
                                    <button
                                        onClick={() => handleRoleSelection(role)}
                                        data-bs-target="#deleteRole"
                                        data-bs-toggle="modal"
                                        className="btn"
                                    >
                                        <i className='text-danger tf-icon ri-delete-bin-6-line ri-20px'></i>
                                    </button>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <DeleteRole fetchRoles={fetchRoles} role={selectedRole} />
            <EditRole fetchRoles={fetchRoles} role={selectedRole} />
        </>
    );
}
