import React from 'react'
import DigitalHealthCardsTable from './digitalcardtable';
import { useState } from 'react';
import axios from 'axios';
import validator from 'validator';
import { toast } from 'react-toastify';
import { useAuth } from '../../../components/utils/loggedin';
import { Helmet } from 'react-helmet';

export default function DigitalCards() {
    const { token } = useAuth()

    const [startRange, setStartRange] = useState("");
    const [endRange, setEndRange] = useState("");
    const [selectedSalesRep, setSelectedSalesRep] = useState(null);
    const [errors, setErrors] = useState({
        startRange: "",
        endRange: "",
    });
    const validateInputs = () => {
        let isValid = true;
        const newErrors = { startRange: "", endRange: "" };
        if (
            !validator.isLength(startRange, { min: 10, max: 10 }) ||
            !validator.isLength(endRange, { min: 10, max: 10 })
        ) {
            newErrors.startRange = "Start Range should be in the format BH00000001";
            newErrors.endRange = "End Range should be in the format BH99999999";
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateInputs()) return;

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/healthcard/generate`,
                {
                    start_range: startRange,
                    end_range: endRange,

                },
                {
                    headers: {
                        Signature: process.env.REACT_APP_API_SIGNATURE,
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response.status === 201) {
                toast.success(response.data.message);
                setStartRange("");
                setEndRange("");
                document.getElementById("closeCanvas").click();

            }
        } catch (error) {
            toast.error("Error Generating Cards");
        }
    };


    return (
        <>
            <Helmet>
                <title>DigitalCards : BharateClinics</title>
            </Helmet>

            <div className="card">
                <div className="card-datatable table-responsive pt-0">
                    <div
                        id="DataTables_Table_0_wrapper"
                        className="dataTables_wrapper dt-bootstrap5 no-footer"
                    >
                        <div className="card-header flex-column flex-md-row border-bottom">
                            <div className="head-label text-center">
                                <h5 className="card-title mb-0">DigitalCards</h5>
                            </div>
                            <div className="dd-action-buttons d-flex align-items-center justify-content-center text-end pt-3 pt-md-0">
                                <div className="dt-buttons btn-group flex-wrap">

                                    <button
                                        className="btn btn-secondary create-new btn-primary waves-effect waves-light" href="javascript:void(0);"
                                        tabIndex={0}
                                        aria-controls="DataTables_Table_0"
                                        type="button"
                                        data-bs-toggle="modal"
                                        data-bs-target="#addNewCCModal"
                                    >
                                        <i class="ri-heart-add-line me-2"></i> Allot Digital Card
                                    </button>{" "}
                                </div>
                            </div>
                        </div>

                        <DigitalHealthCardsTable />

                    </div>
                </div>
                <div className="modal fade"
                    id="addNewCCModal"
                    tabIndex={-1}
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered1 modal-simple modal-add-new-cc">
                        <div className="modal-content">
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                            <div className="modal-body p-0">
                                <div className="text-center mb-6">
                                    <h4 className="mb-2">Add New Digital Smart Health Card</h4>
                                    <p>Add new card to avail benefits</p>
                                </div>
                                <form
                                    id="addNewCCForm"
                                    className="row g-5"
                                    onSubmit={handleSubmit}
                                >
                                    <div className="col-6">
                                        <div className="input-group input-group-merge">
                                            <div className="form-floating form-floating-outline">
                                                <input
                                                    id="startRange"
                                                    name="startRange"
                                                    className={`form-control ${errors.startRange ? "is-invalid" : ""}`}
                                                    type="text"
                                                    placeholder="Start Range (e.g. BH00000001)"
                                                    value={startRange}
                                                    onChange={(e) => setStartRange(e.target.value)}
                                                    aria-describedby="modalAddCard1"
                                                />
                                                <label htmlFor="startRange">Start Range</label>
                                                {errors.startRange && (
                                                    <div className="invalid-feedback">
                                                        {errors.startRange}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-6">
                                        <div className="input-group input-group-merge">
                                            <div className="form-floating form-floating-outline">
                                                <input
                                                    id="endRange"
                                                    name="endRange"
                                                    className={`form-control ${errors.endRange ? "is-invalid" : ""}`}
                                                    type="text"
                                                    placeholder="End Range (e.g. BH99999999)"
                                                    value={endRange}
                                                    onChange={(e) => setEndRange(e.target.value)}
                                                    aria-describedby="modalAddCard2"
                                                />
                                                <label htmlFor="endRange">End Range</label>
                                                {errors.endRange && (
                                                    <div className="invalid-feedback">
                                                        {errors.endRange}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 d-flex flex-wrap justify-content-center gap-4 row-gap-4">
                                        <button type="submit" className="btn btn-primary">
                                            Submit
                                        </button>
                                        <button
                                            type="reset"
                                            className="btn btn-outline-secondary btn-reset"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            id="closeCanvas"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}
