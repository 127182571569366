import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../components/utils/loggedin';
import axios from 'axios';
import EditSms from './editsms';
import OpenTemplate from './opentemplate';
import { Helmet } from 'react-helmet';

export default function SmsTemplate() {
    const { token, admin } = useAuth();
    const [templates, setTemplates] = useState([]);
    const [item, setItem] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [limit, setLimit] = useState(10);
    const pages = 10;
    const [totalItems, setTotalItems] = useState(0);


    const fetchSmsTemplate = async (page = 1) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/sms?page=${page}&limit=${limit}`, {
                headers: {
                    "Signature": process.env.REACT_APP_API_SIGNATURE,
                    "Authorization": `Bearer ${token}`
                }
            });
            // Set the fetched templates to state
            setTemplates(response.data.data);
            setCurrentPage(page);
            setTotalPages(response?.data?.pagination.pageSize);
            setTotalItems(response?.data?.pagination?.totalItems);
        } catch (error) {
            console.error("Error fetching email templates:", error);
        }
    };

    const renderPagination = () => {
        if (totalItems <= limit) return null; // No need for pagination if total items are less than or equal to the limit

        const totalPages = Math.ceil(totalItems / limit); // Calculate total pages based on the limit
        const pageNumbers = [];
        const maxPageButtons = 5; // The number of visible page buttons
        let startPage = Math.max(currentPage - Math.floor(maxPageButtons / 2), 1);
        let endPage = Math.min(startPage + maxPageButtons - 1, totalPages);

        if (endPage - startPage < maxPageButtons - 1) {
            startPage = Math.max(endPage - maxPageButtons + 1, 1);
        }

        // Generate page number buttons
        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(i)}>
                        {i}
                    </button>
                </li>
            );
        }

        return (
            <ul className="pagination">
                {/* Previous button */}
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button
                        className="page-link"
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        <i className="tf-icon ri-arrow-left-s-line ri-20px" />
                    </button>
                </li>

                {/* Display first page and ellipsis if necessary */}
                {startPage > 1 && (
                    <>
                        <li className="page-item">
                            <button className="page-link" onClick={() => handlePageChange(1)}>
                                1
                            </button>
                        </li>
                        {startPage > 2 && (
                            <li className="page-item disabled">
                                <span className="page-link">...</span>
                            </li>
                        )}
                    </>
                )}

                {/* Page numbers */}
                {pageNumbers}

                {/* Display last page and ellipsis if necessary */}
                {endPage < totalPages && (
                    <>
                        {endPage < totalPages - 1 && (
                            <li className="page-item disabled">
                                <span className="page-link">...</span>
                            </li>
                        )}
                        <li className="page-item">
                            <button className="page-link" onClick={() => handlePageChange(totalPages)}>
                                {totalPages}
                            </button>
                        </li>
                    </>
                )}

                {/* Next button */}
                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button
                        className="page-link"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    >
                        <i className="tf-icon ri-arrow-right-s-line ri-20px" />
                    </button>
                </li>
            </ul>
        );
    };

    const handlePageChange = (page) => {
        if (page < 1 || page > totalPages) return;
        fetchSmsTemplate(page);
    };


    useEffect(() => {
        fetchSmsTemplate();
    }, [token, limit]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = String(date.getFullYear()).slice(-2); // Last two digits of the year
        return `${day}/${month}/${year}`;
    }

    // Fetch email templates on component mount
    useEffect(() => {
        fetchSmsTemplate();
    }, []);

    return (
        <>
            <Helmet>
                <title>Manage Sms Templates : BharateClinics</title>
            </Helmet>

            <div className="card">
                <h5 className="card-header text-md-start text-center">
                    SMS Templates
                </h5>
                <div className="card-datatable text-nowrap">
                    <div
                        id="DataTables_Table_0_wrapper"
                        className="dataTables_wrapper dt-bootstrap5 no-footer"
                    >
                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <div className="dataTables_length" id="DataTables_Table_0_length">
                                    <label>
                                        Show{" "}
                                        <select
                                            name="DataTables_Table_0_length"
                                            aria-controls="DataTables_Table_0"
                                            className="form-select form-select-sm"
                                            value={limit}
                                            onChange={(e) => setLimit(e.target.value)}
                                        >
                                            <option value={10}>10</option>
                                            <option value={25}>25</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                        </select>{" "}
                                        entries
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table
                                className="datatables-ajax table table-bordered dataTable no-footer"
                                id="DataTables_Table_0"
                                aria-describedby="DataTables_Table_0_info"
                            >
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>

                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {templates.length > 0 ? (
                                        templates.map((template, index) => (
                                            <tr key={template.id}>
                                                <td>{(currentPage - 1) * pages + index + 1}</td>
                                                <td>{template.name}</td>



                                                <td>
                                                    {admin?.role?.permissions?.ADMIN_PANEL?.sms_template?.update_sms_template && <button button
                                                        onClick={() => setItem(template)}
                                                        data-bs-target="#editTemplate"
                                                        data-bs-toggle="offcanvas"
                                                        className="btn text-primary"
                                                    >
                                                        <i className='ri-edit-line me-2'></i>
                                                    </button>}
                                                    <button
                                                        data-bs-target="#openModal"
                                                        data-bs-toggle="modal"
                                                        className="btn text-primary"
                                                        onClick={() => setItem(template)}
                                                    >
                                                        <i className="ri-eye-line"></i>
                                                    </button>

                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={4} className="dataTables_empty">
                                                No sms templates available.
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            {
                                <EditSms fetchSmsTemplate={fetchSmsTemplate} item={item} />}
                            <OpenTemplate fetchSmsTemplate={fetchSmsTemplate} item={item} />

                        </div>
                        <div className="row mt-3">
                            <nav aria-label="Page navigation">
                                {renderPagination()}
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
