// Frontend: Account.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../../../components/utils/loggedin';
import { toast } from 'react-toastify';


export default function Account({ doctor }) {

    const { token, admin } = useAuth();
    const [otp, setOtp] = useState("");
    const [adminData, setAdminData] = useState("");
    const [doctorData, setDoctorData] = useState({})
    const [resendTimer, setResendTimer] = useState(0); // Timer for resend
    const [resendDisabled, setResendDisabled] = useState(false); // Flag for resend button


    // State for form data
    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        doctor_type: "",
        address: "",
        state: "",
        city: "",
        zip: "",
        profile_img: ""
    });

    useEffect(() => {
        if (doctor) {
            setFormData({
                first_name: doctor.first_name || "",
                last_name: doctor.last_name || "",
                email: doctor.email || "",
                phone: doctor.phone || "",
                doctor_type: doctor.doctor_type || "",
                address: doctor.address || "",
                state: doctor.state || "",
                city: doctor.city || "",
                zip: doctor.zip || "",
                profile_img: doctor.profile_img || ""
            });
        }
    }, [doctor]);

    const fetchDoctorData = async () => {  // Accept  as an argument
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/doctor/getdoctor/${doctor.id}`, {
                headers: {
                    "Signature": process.env.REACT_APP_API_SIGNATURE,
                    Authorization: `Bearer ${token}`,
                }
            });
            console.log("API Response:", response.data.doctor);
            setDoctorData(response.data.doctor);
        } catch (error) {
            console.error('Error fetching doctor data:', error);

        }
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const updateDoctor = async (e) => {
        e.preventDefault();
        const form = new FormData();
        for (const key in formData) {
            form.append(key, formData[key]);
        }
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_API_URL}/doctor/updatedoctorbyadmin`,
                formData,
                {
                    params: { id: doctor.id },
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Signature: process.env.REACT_APP_API_SIGNATURE,
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            toast.success(response.data.message || "Update successful!");
            // document.getElementById("offcanvasEditDoctor").classList.remove("show");
            // document.body.classList.remove("modal-open");
            // document.querySelector(".modal-backdrop")?.remove();
            window.location.reload()
            fetchDoctorData();
        } catch (error) {
            toast.error(error.response?.data?.message || "Failed to update doctor data.");
        }
    };

    const onboardDoctor = async () => {
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_API_URL}/doctor/onboarddoctor/${doctor.id}`,
                {},
                {
                    headers: {
                        Signature: process.env.REACT_APP_API_SIGNATURE,
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            toast.success(response.data.message || "Doctor onboarded successfully!");
            window.location.reload()
            fetchDoctorData(); // Refresh the doctor data to reflect the updated status
        } catch (error) {
            toast.error(error.response?.data?.message || "Failed to onboard doctor.");
        }
    };

    // otp fuction
    const handleChangeOtp = (e, index) => {
        const value = e.target.value;
        if (isNaN(value)) return; // Prevent non-numeric input

        const otpArray = otp.split(""); // Ensure otp is a string
        otpArray[index] = value;

        // Combine the array back into a string
        setOtp(otpArray.join(""));

        // Focus on next input
        if (e.target.nextSibling) {
            e.target.nextSibling.focus();
        }
    };
    // verify otp


    // initiate verification
    const initiateVerification = async () => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/doctor/initiatedoctorverification`,
                {
                    phone: doctor.phone,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Signature: process.env.REACT_APP_API_SIGNATURE,
                    },
                }
            );
            if (response.status === 200) {
                toast.success(response.data.message);
                setResendTimer(10); // Set timer for 30 seconds
                setResendDisabled(true); // Disable resend button
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const resendOtp = async () => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/doctor/initiatedoctorverification`,
                { phone: doctor.phone },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Signature: process.env.REACT_APP_API_SIGNATURE,
                    },
                }
            );
            if (response.status === 200) {
                toast.success("OTP has been resent. Please check your phone.");
                setResendTimer(10); // Reset timer for 30 seconds
                setResendDisabled(true); // Disable resend button
            } else {
                toast.error("Failed to resend OTP.");
            }
        } catch (error) {
            console.error(error);
            toast.error("An error occurred while resending OTP.");
        }
    };


    const verifyDoctor = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/doctor/verifydoctor`,
                { aadhaar_card_number: doctor?.aadhaar_card_number, otp },
                {
                    headers: {
                        Signature: process.env.REACT_APP_API_SIGNATURE,
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            toast.success(response.data.message || "Doctor verified successfully!");
            window.location.reload()
            fetchDoctorData(); // Refresh the doctor data to reflect the updated status
        } catch (error) {
            toast.error(error.response?.data?.message || "Failed to verify doctor.");
        }
    };
    const unverifyDoctor = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/doctor/unverifydoctor/${doctor.id}`,
                {
                    headers: {
                        Signature: process.env.REACT_APP_API_SIGNATURE,
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            toast.success(response.data.message || "Doctor Unverified successfully!");
            window.location.reload()
            fetchDoctorData(); // Refresh the doctor data to reflect the updated status
        } catch (error) {
            toast.error(error.response?.data?.message || "Failed to Unverify doctor.");
        }
    };



    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFormData({
                ...formData,
                profile_img: file,
            });
        }
    };


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = String(date.getFullYear()).slice(-2);

        return `${day}/${month}/${year}`;
    };

    useEffect(() => {
        if (resendTimer > 0) {
            const timer = setInterval(() => {
                setResendTimer((prev) => prev - 1);
            }, 1000);
            return () => clearInterval(timer); // Cleanup timer on unmount
        } else {
            setResendDisabled(false); // Re-enable resend button when timer reaches 0
        }
    }, [resendTimer]);

    return (
        <>
            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <h5 className="mb-0">Doctor Details</h5>
                    <div className="d-flex">
                        {
                            admin?.role?.permissions?.ADMIN_PANEL?.doctor?.onboard_doctor && (
                                <button
                                    className={`btn btn-primary me-3 ${doctor.onboarded ? 'disabled' : ''}`}
                                    data-bs-toggle="modal"
                                    data-bs-target="#onboardDoctorModal"
                                    disabled={doctor.onboarded}
                                >
                                    {doctor.onboarded ? "Onboarded" : "Onboard"}
                                </button>
                            )
                        }

                        {(adminData.admin_type === "superadmin" ? "" : doctor.verified ?
                            <>

                                {admin?.role?.permissions?.ADMIN_PANEL?.doctor?.suspend_doctor &&
                                    <button
                                        data-bs-toggle="modal"
                                        data-bs-target="#unverify"
                                        className="btn btn-outline-danger suspend-user me-3"
                                    >
                                        Suspend
                                    </button>}
                            </>
                            : <>

                                {admin?.role?.permissions?.ADMIN_PANEL?.doctor?.verify_doctor && <button
                                    data-bs-toggle="modal"
                                    data-bs-target="#verifyOTP"
                                    className="btn btn-outline-success suspend-user me-3"
                                    onClick={(e) => initiateVerification(e)}
                                >
                                    Verify
                                </button>}
                            </>
                        )}
                        {admin?.role?.permissions?.ADMIN_PANEL?.doctor?.edit_doctors &&
                            <button
                                className="btn btn-label-primary"
                                data-bs-target="#offcanvasEditDoctor"
                                data-bs-toggle="offcanvas"
                            >
                                <i className="ri-pencil-line me-2"></i>
                                Edit
                            </button>}
                    </div>


                </div>
                <div className="table-responsive text-nowrap">
                    <table className="table">
                        <tbody className="table-border-bottom-0">
                            <tr>
                                <td><span className="fw-medium">Name</span></td>
                                <td>{doctor.first_name + " " + doctor.last_name}</td>
                            </tr>
                            <tr>
                                <td><span className="fw-medium">Email</span></td>
                                <td>{doctor.email}</td>
                            </tr>
                            <tr>
                                <td><span className="fw-medium">Phone</span></td>
                                <td>{doctor.phone}</td>
                            </tr>
                            <tr>
                                <td><span className="fw-medium">Status</span></td>
                                <td>{doctor.verified ? "Verified " : "Not Verified"}</td>
                            </tr>
                            <tr>
                                <td><span className="fw-medium">Status</span></td>
                                <td>{doctor.doctor_type}</td>
                            </tr>
                            <tr>
                                <td><span className="fw-medium">Address</span></td>
                                <td>{`${doctor.address}, ${doctor.city}, ${doctor.state}, ${doctor.zip}`}</td>
                            </tr>
                            <tr>
                                <td><span className="fw-medium">Joined On</span></td>
                                <td>{formatDate(doctor.created_at)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Edit Doctor Modal */}
            <div
                className="offcanvas offcanvas-end"
                tabIndex={-1}
                id="offcanvasEditDoctor"
                aria-labelledby="offcanvasEditDoctorLabel"
                style={{ width: '600px' }}
            >
                <div className="offcanvas-header border-bottom">
                    <h5 id="offcanvasEditDoctorLabel" className="offcanvas-title">
                        Edit Doctor
                    </h5>
                    <button
                        type="button"
                        className="btn-close text-reset"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                    />

                </div>
                <div className="offcanvas-body mx-0 flex-grow-0 h-100">
                    <form
                        className="add-new-user pt-0"
                        id="editDoctor"
                        onSubmit={(e) => updateDoctor(e)}
                    >
                        <div className="form-floating form-floating-outline mb-5">
                            <input
                                type="text"
                                id="edit-doctor-firstname"
                                className="form-control"
                                name="first_name"
                                value={formData.first_name}
                                onChange={handleChange}
                            />
                            <label htmlFor="edit-doctor-firstname">First Name</label>
                        </div>
                        <div className="form-floating form-floating-outline mb-5">
                            <input
                                type="text"
                                id="edit-doctor-lastname"
                                className="form-control"
                                name="last_name"
                                value={formData.last_name}
                                onChange={handleChange}
                            />
                            <label htmlFor="edit-doctor-lastname">Last Name</label>
                        </div>
                        <div className="form-floating form-floating-outline mb-5">
                            <input
                                type="email"
                                id="edit-doctor-email"
                                className="form-control"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                            />
                            <label htmlFor="edit-doctor-email">Email</label>
                        </div>
                        <div className="form-floating form-floating-outline mb-5">
                            <input
                                type="text"
                                id="edit-doctor-phone"
                                className="form-control"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                            />
                            <label htmlFor="edit-doctor-phone">Phone</label>
                        </div>
                        {doctor.onboarded ? <div className="form-floating form-floating-outline mb-5">
                            <select
                                className="form-select"
                                id="edit-doctor-type"
                                name="doctor_type"
                                value={formData.doctor_type}
                                onChange={handleChange}
                            >
                                <option value="">Select Doctor Type</option>
                                <option value="Bharat_eClinics_doctor">Bharat eClinics Doctor</option>
                                <option value="Online Doctor">Online Doctor</option>
                                <option value="Offline Doctor">Offline Doctor</option>
                            </select>
                            <label htmlFor="edit-doctor-type">Doctor Type</label>
                        </div> : ""}

                        <div className="form-floating form-floating-outline mb-5">
                            <input
                                type="text"
                                id="edit-doctor-address"
                                className="form-control"
                                name="address"
                                value={formData.address}
                                onChange={handleChange}
                            />
                            <label htmlFor="edit-doctor-address">Address</label>
                        </div>
                        <div className="form-floating form-floating-outline mb-5">
                            <input
                                type="text"
                                id="edit-doctor-city"
                                className="form-control"
                                name="city"
                                value={formData.city}
                                onChange={handleChange}
                            />
                            <label htmlFor="edit-doctor-city">City</label>
                        </div>
                        <div className="form-floating form-floating-outline mb-5">
                            <input
                                type="text"
                                id="edit-doctor-state"
                                className="form-control"
                                name="state"
                                value={formData.state}
                                onChange={handleChange}
                            />
                            <label htmlFor="edit-doctor-state">State</label>
                        </div>
                        <div className="form-floating form-floating-outline mb-5">
                            <input
                                type="text"
                                id="edit-doctor-zip"
                                className="form-control"
                                name="zip"
                                value={formData.zip}
                                onChange={handleChange}
                            />
                            <label htmlFor="edit-doctor-zip">Zip Code</label>
                        </div>
                        <div className="mb-5">
                            <label htmlFor="edit-doctor-profile-img" className="form-label">Profile Image</label>
                            <input
                                type="file"
                                id="edit-doctor-profile-img"
                                className="form-control"
                                accept="image/*"
                                onChange={handleImageChange}
                            />
                        </div>
                        <div className="d-flex justify-content-end">
                            <button type="submit" className="btn btn-primary me-3">Save</button>
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="offcanvas">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>

            {/* verify model  */}
            <div
                className="modal fade"
                id="verifyOTP"
                tabIndex={-1}
                aria-hidden="true"
            >
                <div className="modal-dialog modal-simple modal-enable-otp modal-dialog-centered">
                    <div className="modal-content">
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        />
                        <div className="card-body mt-1">
                            <h4 className="mb-1"> Verification of the user 💬</h4>
                            <p className="text-start mb-5">
                                We sent a verification code to your mobile. Enter the code from
                                the mobile in the field below.
                                <span className="d-block mt-1 h6">{doctor.phone}</span>
                            </p>
                            <p className="mb-0">Type your 6 digit security code</p>
                            <form id="twoStepsForm" onSubmit={(e) => verifyDoctor(e)}>

                                <div className="mb-3">
                                    <div className="auth-input-wrapper d-flex align-items-center justify-content-between numeral-mask-wrapper">
                                        {[...Array(6)].map((_, index) => (
                                            <input
                                                key={index}
                                                type="tel"
                                                className="form-control auth-input text-center numeral-mask h-px-50 mx-sm-1 my-2"
                                                maxLength={1}
                                                value={otp[index] || ""}
                                                onChange={(e) => handleChangeOtp(e, index)}
                                                onFocus={(e) => e.target.select()}
                                                autoFocus={index === 0}
                                            />
                                        ))}
                                    </div>
                                    {/* Hidden field not necessary when using string */}
                                </div>
                                <button
                                    data-bs-dismiss="modal"
                                    className="btn btn-primary d-grid w-100 mb-5"
                                    type="submit"
                                >
                                    Verify my account
                                </button>
                                <div className="text-center">
                                    Didn't get the code?
                                    {resendTimer > 0 ? (
                                        <span style={{ color: "blue", padding: "2px" }}>Resend in {resendTimer}s</span>
                                    ) : (
                                        <a href="javascript:void(0);" onClick={resendOtp}>Resend</a>
                                    )}

                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {/* unverify model  */}
            <div
                className="modal fade"
                id="unverify"
                tabIndex={-1}
                aria-hidden="true"
            >
                <div className="modal-dialog modal-simple modal-enable-otp modal-dialog-centered">
                    <div className="modal-content">
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        />
                        <div className="modal-body p-0">
                            <div className="text-center mb-6">
                                <h2 className="mb-2">Are you sure !</h2>
                                {/* <p>Verify Your Mobile Number for SMS</p> */}
                            </div>
                            <p className="mb-5 text-center">
                                Are you sure you want to unverify this admin account?
                                <br /> Please confirm your decision to proceed with the
                                unverification process.
                            </p>

                            <form
                                id="enableOTPForm"
                                className="row g-5"
                                onsubmit="return false"
                            >
                                {/* <div className="col-12">
                  <div className="input-group input-group-merge">
                    <span className="input-group-text">US (+1)</span>
                    <div className="form-floating form-floating-outline">
                      <input
                        type="text"
                        id="modalEnableOTPPhone"
                        name="modalEnableOTPPhone"
                        className="form-control phone-number-otp-mask"
                        placeholder="202 555 0111"
                      />
                      <label htmlFor="modalEnableOTPPhone">Phone Number</label>
                    </div>
                  </div>
                </div> */}
                                <div className="col-12 d-flex flex-wrap justify-content-center gap-4 row-gap-4">
                                    <button
                                        type="submit"
                                        className="btn btn-success"
                                        onClick={(e) => unverifyDoctor(e)}
                                    >
                                        Unverify
                                    </button>
                                    <button
                                        type="reset"
                                        className="btn btn-outline-danger"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {/* onboardDoctor model  */}
            <div
                className="modal fade"
                id="onboardDoctorModal"
                tabIndex={-1}
                aria-labelledby="onboardSalesRepModalLabel"
                aria-hidden="true"
                data-bs-dismiss="modal"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="onboardSalesRepModalLabel">Onboard Sales Representative</h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            Are you sure you want to onboard this sales representative?
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => onboardDoctor(doctor.id)}
                                data-bs-dismiss="modal"

                            >
                                Yes, Onboard
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
