import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../../components/utils/loggedin';
import { toast } from 'react-toastify';
import DeleteContact from './deletecontact';

export default function AddContact({ renderPagination }) {
    const { token, admin } = useAuth();
    const [deleteItem, setDeleteItem] = useState(null);
    const [contact, setContact] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
    });

    const [contacts, setContacts] = useState([]);
    const [loading, setLoading] = useState(true);

    // Fetch contact list from the API
    const fetchContacts = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/contact-us`, {
                headers: {
                    'Signature': process.env.REACT_APP_API_SIGNATURE,
                    'Authorization': `Bearer ${token}`,
                },
            });

            // Check if the response is an array
            if (Array.isArray(response.data.data)) {
                setContacts(response.data.data);
            } else {
                // Handle non-array response
                console.error('Expected array but received:', response.data);
                setContacts([]); // Reset contacts to empty array if not valid
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching contacts:', error);
            setLoading(false);
            toast.error('Failed to load contacts'); // Show error message
        }
    };

    // Fetch the contacts when the component mounts
    useEffect(() => {
        fetchContacts();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setContact((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/contact-us`, contact, {
                headers: {
                    'Content-Type': 'application/json',
                    'Signature': process.env.REACT_APP_API_SIGNATURE,
                },
            });
            // After adding the contact, fetch the updated list
            fetchContacts();
            // Reset the form
            setContact({
                name: '',
                email: '',
                phone: '',
                subject: '',
                message: '',
            });
            toast.success('Contact added successfully!');
        } catch (error) {
            console.error('Error adding contact:', error);
            toast.error('Failed to add contact'); // Show error message
        }
    };

    return (
        <div className="table-responsive">
            <table
                className="datatables-ajax table table-bordered dataTable no-footer"
                aria-describedby="DataTables_Table_0_info"
            >
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Full Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Subject</th>
                        <th>Message</th>
                        {admin?.role?.permissions?.ADMIN_PANEL?.contact?.delete_contact && <th>Action</th>}
                    </tr>
                </thead>
                <tbody>
                    {loading ? (
                        <tr>
                            <td colSpan="6">Loading...</td>
                        </tr>
                    ) : contacts.length === 0 ? (
                        <tr>
                            <td colSpan="6">No contacts available</td>
                        </tr>
                    ) : (
                        contacts.map((contactItem, index) => (
                            <tr key={contactItem.id}>
                                <td>{index + 1}</td> {/* Serial number */}
                                <td>{contactItem.name}</td>
                                <td>{contactItem.email}</td>
                                <td>{contactItem.phone}</td>
                                <td>{contactItem.subject}</td>
                                <td>{contactItem.message}</td>
                                {admin?.role?.permissions?.ADMIN_PANEL?.contact?.delete_contact && <td><button
                                    onClick={() => setDeleteItem(contactItem)}
                                    data-bs-target="#deleteTestimonial"
                                    data-bs-toggle="modal"
                                    className="btn text-danger"
                                >
                                    <i className='ri-delete-bin-line me-2'></i>
                                </button></td>}
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
            <div className="row mt-3">
                <nav aria-label="Page navigation">
                    {renderPagination()}
                </nav>
            </div>
            <DeleteContact contact={deleteItem} fetchContacts={fetchContacts} />

            {/* Offcanvas for adding new contact */}
            <div
                className="offcanvas offcanvas-end"
                tabIndex={-1}
                id="offcanvasAddContact"
                aria-labelledby="offcanvasAddContactLabel"
                style={{ width: '600px' }}
            >
                <div className="offcanvas-header border-bottom">
                    <h5 id="offcanvasAddContactLabel" className="offcanvas-title">Add Contact</h5>
                    <button
                        type="button"
                        className="btn-close text-reset"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                    />
                </div>

                <div className="offcanvas-body mx-0 flex-grow-0 h-100">
                    <form className="add-new-contact pt-0" id="addContact" onSubmit={handleSubmit}>
                        {/* Name Field */}
                        <div className="form-floating form-floating-outline mb-5">
                            <input
                                type="text"
                                id="contact-name"
                                className="form-control"
                                name="name"
                                value={contact.name}
                                onChange={handleChange}
                            />
                            <label htmlFor="contact-name">Name</label>
                        </div>

                        {/* Email Field */}
                        <div className="form-floating form-floating-outline mb-5">
                            <input
                                type="email"
                                id="contact-email"
                                className="form-control"
                                name="email"
                                value={contact.email}
                                onChange={handleChange}
                            />
                            <label htmlFor="contact-email">Email</label>
                        </div>

                        {/* Phone Field */}
                        <div className="form-floating form-floating-outline mb-5">
                            <input
                                type="tel"
                                id="contact-phone"
                                className="form-control"
                                name="phone"
                                value={contact.phone}
                                onChange={handleChange}
                            />
                            <label htmlFor="contact-phone">Phone</label>
                        </div>

                        {/* Subject Field */}
                        <div className="form-floating form-floating-outline mb-5">
                            <input
                                type="text"
                                id="contact-subject"
                                className="form-control"
                                name="subject"
                                value={contact.subject}
                                onChange={handleChange}
                            />
                            <label htmlFor="contact-subject">Subject</label>
                        </div>

                        {/* Message Field */}
                        <div className="form-floating form-floating-outline mb-5">
                            <textarea
                                id="contact-message"
                                className="form-control"
                                name="message"
                                value={contact.message}
                                onChange={handleChange}
                                style={{ height: '150px' }}
                            />
                            <label htmlFor="contact-message">Message</label>
                        </div>

                        {/* Submit Buttons */}
                        <div className="d-flex justify-content-end">
                            <button
                                type="submit"
                                className="btn btn-primary me-3"
                            >
                                Save
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="offcanvas"
                            >
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
