import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import validator from 'validator';
import { permissions as defaultPermissions } from '../../components/utils/permission';
import { useAuth } from '../../components/utils/loggedin';

export default function EditRole({ fetchRoles, role }) {
    const { token } = useAuth();
    const [roleName, setRoleName] = useState('');
    const [status, setStatus] = useState('');
    const [permissionsState, setPermissionsState] = useState({});

    // Helper function to merge permissions
    const mergePermissions = (defaultPerms, existingPerms) => {
        const mergedPerms = JSON.parse(JSON.stringify(defaultPerms));

        Object.keys(mergedPerms).forEach(category => {
            Object.keys(mergedPerms[category]).forEach(subcategory => {
                Object.keys(mergedPerms[category][subcategory]).forEach(permission => {
                    if (existingPerms && existingPerms[category] && existingPerms[category][subcategory] && existingPerms[category][subcategory].hasOwnProperty(permission)) {
                        mergedPerms[category][subcategory][permission] = existingPerms[category][subcategory][permission];
                    }
                });
            });
        });

        return mergedPerms;
    };

    useEffect(() => {
        if (role) {
            setRoleName(role.name);
            setStatus(role.status);

            // If role.permissions exists, merge it with the default permissions
            const mergedPermissions = mergePermissions(
                defaultPermissions[0], // Since your permissions object is inside an array
                role.permissions || {}  // Use an empty object if role.permissions is null
            );
            setPermissionsState(mergedPermissions);
        } else {
            // Set default permissions if role is not passed
            setPermissionsState(defaultPermissions[0]);
        }
    }, [role]);

    const [errors, setErrors] = useState({});

    const handleCheckboxChange = (category, subcategory, permission) => {
        setPermissionsState(prevState => ({
            ...prevState,
            [category]: {
                ...prevState[category],
                [subcategory]: {
                    ...prevState[category][subcategory],
                    [permission]: !prevState[category][subcategory][permission]
                }
            }
        }));
    };

    const validateForm = () => {
        const newErrors = {};

        if (validator.isEmpty(roleName)) {
            newErrors.roleName = 'Role name is required';
        }

        if (validator.isEmpty(status)) {
            newErrors.status = 'Status is required';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async () => {
        if (!validateForm()) {
            toast.error('Please fill in all required fields.');
            return;
        }
        try {
            const formData = {
                name: roleName,
                status,
                permissions: permissionsState,
            };
            await axios.put(`${process.env.REACT_APP_API_URL}/role/${role.id}`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    Signature: `${process.env.REACT_APP_API_SIGNATURE}`,
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success('Role updated successfully!');
            document.getElementById('closeModal').click();
            fetchRoles();
        } catch (error) {
            if (error.response && error.response.status === 400) {
                toast.error(error.response.data.message);
            } else {
                toast.error('Failed to update role');
            }
            console.error('Error updating role:', error);
        }
    };

    return (
        <div className="modal fade" id="editRoleModal" tabIndex={-1} aria-hidden="true">
            <div className="modal-dialog modal-xl modal-simple modal-dialog-centered modal-add-new-role">
                <div className="modal-content">
                    <button type="button" id="closeModal" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                    <div className="modal-body p-0">
                        <div className="text-center mb-6">
                            <h4 className="role-title mb-2 pb-0">Edit Role</h4>
                            <p>Update role permissions</p>
                        </div>
                        <form
                            id="editRoleForm"
                            className="row g-3"
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleSubmit();
                            }}
                        >
                            <div className="col-6 mb-3">
                                <div className="form-floating form-floating-outline">
                                    <input
                                        type="text"
                                        id="modalRoleName"
                                        name="modalRoleName"
                                        className={`form-control ${errors.roleName ? 'is-invalid' : ''}`}
                                        placeholder="Enter a role name"
                                        value={roleName}
                                        onChange={(e) => setRoleName(e.target.value)}
                                    />
                                    <label htmlFor="modalRoleName">Role Name</label>
                                    {errors.roleName && <div className="invalid-feedback">{errors.roleName}</div>}
                                </div>
                            </div>
                            <div className="col-6 mb-3">
                                <div className="form-floating form-floating-outline">
                                    <select
                                        id="modalStatus"
                                        name="modalStatus"
                                        className={`form-select ${errors.status ? 'is-invalid' : ''}`}
                                        value={status}
                                        onChange={(e) => setStatus(e.target.value)}
                                    >
                                        <option value="">Select status</option>
                                        <option value="active">Active</option>
                                        <option value="inactive">Inactive</option>
                                        <option value="suspended">Suspended</option>
                                    </select>
                                    <label htmlFor="modalStatus">Status</label>
                                    {errors.status && <div className="invalid-feedback">{errors.status}</div>}
                                </div>
                            </div>
                            <div className="col-12 border-bottom">
                                <h5 className="mb-6">Role Permissions</h5>
                            </div>
                            {Object.entries(permissionsState).map(([category, subcategories]) => (
                                <div key={category} className="col-12 border-bottom pb-4 mb-4">
                                    <h5 className='text-capitalize'>{category.replace(/_/g, ' ')}</h5>
                                    {Object.entries(subcategories).map(([subcategory, permissions]) => (
                                        <div key={subcategory} className="mb-3">
                                            <h6 className='text-capitalize'>{subcategory.replace(/_/g, ' ')}</h6>
                                            <div className="d-flex flex-wrap gap-4">
                                                {Object.entries(permissions).map(([permission, value]) => (
                                                    <div className="form-check mb-0 mt-1" key={permission}>
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id={`${category}_${subcategory}_${permission}`}
                                                            checked={value}
                                                            onChange={() => handleCheckboxChange(category, subcategory, permission)}
                                                        />
                                                        <label
                                                            className="form-check-label text-capitalize"
                                                            htmlFor={`${category}_${subcategory}_${permission}`}
                                                        >
                                                            {permission.replace(/_/g, ' ')}
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))}
                            <div className="col-12 d-flex flex-wrap justify-content-center gap-4 row-gap-4">
                                <button type="submit" className="btn btn-success">
                                    Update
                                </button>
                                <button
                                    type="reset"
                                    id="closeEditRoleModal"
                                    className="btn btn-outline-secondary"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                >
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}