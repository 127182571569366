import React, { useState } from 'react';
import axios from 'axios';
import { useAuth } from '../../components/utils/loggedin';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';


const DoctorForm = () => {
  const { token } = useAuth()
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    aadhaar_card_number: '',
    phone: '',
    password: '',
    address: '',
    latitude: '',
    longitude: '',
    city: '',
    state: '',
    zip: '',
    price: '',
    age: '',
    gender: '',
    doctor_type: '',
    mci_number: '',
    experience: [{ position: '', hospital: '', startDate: '', endDate: '' }],
    qualification: [{ qualification: '', university: '', startDate: '', endDate: '' }],
    specializations: [{ specialization: '', startDate: '', endDate: '' }],
    time_slots: [{ day: '', startTime: '', endTime: '' }],
    about: ''
  });




  const fetchLocationDetailsByPinCode = async (pincode) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/address/${pincode}`, {
        headers: {
          "Signature": process.env.REACT_APP_API_SIGNATURE,
        }
      });

      if (response.data && response.data.length > 0) {
        const locationData = response.data[0];

        // Map the fields from API response to your state
        setFormData(prevState => ({
          ...prevState,
          address: locationData.street || "",
          city: locationData.city || "",
          state: locationData.state || "",
          zip: locationData.postalCode || "",
          latitude: locationData.latitude || "",
          longitude: locationData.longitude || "",

        }));
      } else {
        toast.warning("No valid location data found for the given pincode.");
      }
    } catch (error) {
      toast.error("Error while fetching location by pincode:", error);
    }
  };

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === 'zip' && value.length === 6) {
      fetchLocationDetailsByPinCode(value);
    }
  };

  const handleTimeSlotChange = (index, e) => {
    const { name, value } = e.target;
    const newTimeSlots = [...formData.time_slots];
    newTimeSlots[index] = { ...newTimeSlots[index], [name]: value };
    setFormData({ ...formData, time_slots: newTimeSlots });
  };

  const addTimeSlot = () => {
    setFormData({
      ...formData,
      time_slots: [...formData.time_slots, { day: '', startTime: '', endTime: '' }]
    });
  };

  const removeTimeSlot = (index) => {
    const newTimeSlots = formData.time_slots.filter((_, i) => i !== index);
    setFormData({ ...formData, time_slots: newTimeSlots });
  };

  const handleExperienceChange = (index, e) => {
    const { name, value } = e.target;
    const newExperience = [...formData.experience];
    newExperience[index] = { ...newExperience[index], [name]: value };
    setFormData({ ...formData, experience: newExperience });
  };

  const addExperience = () => {
    setFormData({
      ...formData,
      experience: [...formData.experience, { position: '', hospital: '', startDate: '', endDate: '' }]
    });
  };

  const removeExperience = (index) => {
    const newExperience = formData.experience.filter((_, i) => i !== index);
    setFormData({ ...formData, experience: newExperience });
  };

  const handleQualificationChange = (index, e) => {
    const { name, value } = e.target;
    const newQualifications = [...formData.qualification];
    newQualifications[index] = { ...newQualifications[index], [name]: value };
    setFormData({ ...formData, qualification: newQualifications });
  };

  const addQualification = () => {
    setFormData({
      ...formData,
      qualification: [...formData.qualification, { qualification: '', university: '', startDate: '', endDate: '' }]
    });
  };

  const removeQualification = (index) => {
    const newQualifications = formData.qualification.filter((_, i) => i !== index);
    setFormData({ ...formData, qualification: newQualifications });
  };

  const handleSpecializationChange = (index, e) => {
    const { name, value } = e.target;
    const newSpecializations = [...formData.specializations];
    newSpecializations[index] = { ...newSpecializations[index], [name]: value };
    setFormData({ ...formData, specializations: newSpecializations });
  };

  const addSpecialization = () => {
    setFormData({
      ...formData,
      specializations: [...formData.specializations, { specialization: '', startDate: '', endDate: '' }]
    });
  };

  const removeSpecialization = (index) => {
    const newSpecializations = formData.specializations.filter((_, i) => i !== index);
    setFormData({ ...formData, specializations: newSpecializations });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/doctor/registerbyadmin`,
        formData,
        {
          headers: {
            Signature: process.env.REACT_APP_API_SIGNATURE,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 201) {
        // const { aadhaar_card_number, phone } = formData;
        navigate("/doctors");
        // navigate("/doctors/verify/otp", {
        //   state: { aadhaar_card_number, phone },
        // });
        toast.success(response.data.message);
      }
    } catch (error) {
      console.error(error);
      toast.error(error?.response?.data?.message || "Error registering doctor");
    }
    console.log(formData);
  };


  return (
    <div className="card mb-8">
      <form className="card-body" onSubmit={handleSubmit}>
        {/* Personal Information */}
        <div className="mb-5">
          <h4>Personal Information</h4>
          <div className="row">
            <div className="col-sm-6 mb-3">
              <input
                type="text"
                name="first_name"
                value={formData.first_name}
                onChange={handleChange}
                placeholder="First Name"
                className={`form-control ${errors.first_name ? 'is-invalid' : ''}`}
                required
              />
              <div className="invalid-feedback">{errors.first_name}</div>
            </div>
            <div className="col-sm-6 mb-3">
              <input
                type="text"
                name="last_name"
                value={formData.last_name}
                onChange={handleChange}
                placeholder="Last Name"
                className={`form-control ${errors.last_name ? 'is-invalid' : ''}`}
                required
              />
              <div className="invalid-feedback">{errors.last_name}</div>
            </div>
            <div className="col-sm-6 mb-3">
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email"
                className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                required
              />
              <div className="invalid-feedback">{errors.email}</div>
            </div>
            <div className="col-sm-6 mb-3">
              <input
                type="text"
                name="aadhaar_card_number"
                value={formData.aadhaar_card_number}
                onChange={handleChange}
                placeholder="Aadhaar Card Number"
                className={`form-control ${errors.aadhaar_card_number ? 'is-invalid' : ''}`}
                required
              />
              <div className="invalid-feedback">{errors.aadhaar_card_number}</div>
            </div>
            <div className="col-sm-6 mb-3">
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="Phone"
                className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
                required

              />
              <div className="invalid-feedback">{errors.phone}</div>
            </div>
            <div className="col-sm-6 mb-3">
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Password"
                className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                required
              />
              <div className="invalid-feedback">{errors.password}</div>
            </div>
          </div>
          <div className='mb-5'>
            <h4>Address Details</h4>
            <div className='row'>
              <div className="col-sm-6 mb-3">
                <input
                  type="text"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  placeholder="Address"
                  className={`form-control ${errors.address ? 'is-invalid' : ''}`}
                  required
                />
                <div className="invalid-feedback">{errors.address}</div>
              </div>
              <div className="col-sm-6 mb-3">
                <input
                  type="text"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  placeholder="City"
                  className={`form-control ${errors.city ? 'is-invalid' : ''}`}
                  required
                />
                <div className="invalid-feedback">{errors.city}</div>
              </div>
              <div className="col-sm-6 mb-3">
                <input
                  type="text"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  placeholder="State"
                  className={`form-control ${errors.state ? 'is-invalid' : ''}`}
                  required
                />
                <div className="invalid-feedback">{errors.state}</div>
              </div>
              <div className="col-sm-6 mb-3">
                <input
                  type="text"
                  name="zip"
                  value={formData.zip}
                  onChange={handleChange}
                  placeholder="Zip"
                  className={`form-control ${errors.zip ? 'is-invalid' : ''}`}
                  required
                />
                <div className="invalid-feedback">{errors.zip}</div>
              </div>
              <div className="col-sm-6 mb-3">
                <input
                  type="text"
                  name="latitude"
                  value={formData.latitude}
                  onChange={handleChange}
                  placeholder="Latitude"
                  className={`form-control ${errors.latitude ? 'is-invalid' : ''}`}
                  required
                />
                <div className="invalid-feedback">{errors.latitude}</div>
              </div>
              <div className="col-sm-6 mb-3">
                <input
                  type="text"
                  name="longitude"
                  value={formData.longitude}
                  onChange={handleChange}
                  placeholder="Longitude"
                  className={`form-control ${errors.longitude ? 'is-invalid' : ''}`}
                  required
                />
                <div className="invalid-feedback">{errors.longitude}</div>
              </div>
            </div>
          </div>
        </div>


        {/* Professional Information */}
        <div className="mb-5">
          <h4>Professional Information</h4>
          <div className="row">
            <div className="col-sm-6 mb-3">
              <input
                type="number"
                name="age"
                value={formData.age}
                onChange={handleChange}
                placeholder="Age"
                className={`form-control ${errors.age ? 'is-invalid' : ''}`}
                required
              />
              <div className="invalid-feedback">{errors.age}</div>
            </div>
            <div className="col-sm-6 mb-3">
              <select
                name="gender"
                value={formData.gender}
                onChange={handleChange}
                className={`form-control ${errors.gender ? 'is-invalid' : ''}`}
                required
              >
                <option value="">Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </select>
              <div className="invalid-feedback">{errors.gender}</div>
            </div>
            <div className="col-sm-6 mb-3">
              <select
                name="doctor_type"
                value={formData.doctor_type}
                onChange={handleChange}
                className={`form-control ${errors.doctor_type ? 'is-invalid' : ''}`}
                required
              >
                <option value="">Select Doctor Type</option>
                <option value="Bharat_eClinics_doctor">Bharat eClinics Doctor</option>
                <option value="Online Doctor">Online Doctor</option>
                <option value="Offlin Doctor">Offline Doctor</option>
              </select>
              <div className="invalid-feedback">{errors.doctor_type}</div>
            </div>
            <div className="col-sm-6 mb-3">
              <input
                type="text"
                name="mci_number"
                value={formData.mci_number}
                onChange={handleChange}
                placeholder="MCI Number"
                className={`form-control ${errors.mci_number ? 'is-invalid' : ''}`}
                required
              />
              <div className="invalid-feedback">{errors.mci_number}</div>
            </div>
            <div className="col-sm-6 mb-3">
              <input
                type="number"
                name="price"
                value={formData.price}
                onChange={handleChange}
                placeholder="Fee"
                className={`form-control ${errors.price ? 'is-invalid' : ''}`}
                required
              />
              <div className="invalid-feedback">{errors.price}</div>
            </div>
          </div>
        </div>

        {/* Time Slots */}
        <div className="mb-5">
          <h4>Time Slots</h4>
          {formData.time_slots.map((slot, index) => (
            <div key={index} className="row mb-3">
              <div className="col-sm-4">
                <select
                  name="day"
                  value={slot.day}
                  onChange={(e) => handleTimeSlotChange(index, e)}
                  className="form-control"
                  required
                >
                  <option value="">Select Day</option>
                  <option value="Monday">Monday</option>
                  <option value="Tuesday">Tuesday</option>
                  <option value="Wednesday">Wednesday</option>
                  <option value="Thursday">Thursday</option>
                  <option value="Friday">Friday</option>
                  <option value="Saturday">Saturday</option>
                  <option value="Sunday">Sunday</option>
                </select>
              </div>
              <div className="col-sm-4">
                <input
                  type="time"
                  name="startTime"
                  value={slot.startTime}
                  onChange={(e) => handleTimeSlotChange(index, e)}
                  placeholder="Start Time"
                  className="form-control"
                  required
                />
              </div>
              <div className="col-sm-4">
                <input
                  type="time"
                  name="endTime"
                  value={slot.endTime}
                  onChange={(e) => handleTimeSlotChange(index, e)}
                  placeholder="End Time"
                  className="form-control"
                  required
                />
              </div>
              <div className="col-sm-12 mt-2">
                <button
                  type="button"
                  onClick={() => removeTimeSlot(index)}
                  className="btn btn-sm btn-label-danger"
                >
                  Remove Time Slot
                </button>
              </div>
            </div>
          ))}
          <button
            type="button"
            onClick={addTimeSlot}
            className="btn btn-sm btn-label-primary"
          >
            Add Time Slot
          </button>
        </div>


        {/* Experience */}
        <div className="mb-5">
          <h4>Experience</h4>
          {formData.experience.map((exp, index) => (
            <div key={index} className="row mb-3">
              <div className="col-sm-4">
                <input
                  type="text"
                  name="position"
                  value={exp.position}
                  onChange={(e) => handleExperienceChange(index, e)}
                  placeholder="Position"
                  className="form-control"
                  required
                />
              </div>
              <div className="col-sm-4">
                <input
                  type="text"
                  name="hospital"
                  value={exp.hospital}
                  onChange={(e) => handleExperienceChange(index, e)}
                  placeholder="Hospital"
                  className="form-control"
                  required
                />
              </div>
              <div className="col-sm-2">
                <input
                  type="date"
                  name="startDate"
                  value={exp.startDate}
                  onChange={(e) => handleExperienceChange(index, e)}
                  placeholder="Start Date"
                  className="form-control"
                  required
                />
              </div>
              <div className="col-sm-2">
                <input
                  type="date"
                  name="endDate"
                  value={exp.endDate}
                  onChange={(e) => handleExperienceChange(index, e)}
                  placeholder="End Date"
                  className="form-control"
                  required
                />
              </div>
              <div className="col-sm-12 mt-2">
                <button type="button" onClick={() => removeExperience(index)} className="btn btn-sm btn-label-danger">
                  Remove Experience
                </button>
              </div>
            </div>
          ))}
          <button type="button" onClick={addExperience} className="btn btn-sm btn-label-primary">
            Add Experience
          </button>
        </div>

        {/* Qualifications */}
        <div className="mb-5">
          <h4>Qualifications</h4>
          {formData.qualification.map((qual, index) => (
            <div key={index} className="row mb-3">
              <div className="col-sm-4">
                <input
                  type="text"
                  name="qualification"
                  value={qual.qualification}
                  onChange={(e) => handleQualificationChange(index, e)}
                  placeholder="Qualification"
                  className="form-control"
                  required
                />
              </div>
              <div className="col-sm-4">
                <input
                  type="text"
                  name="university"
                  value={qual.university}
                  onChange={(e) => handleQualificationChange(index, e)}
                  placeholder="University"
                  className="form-control"
                  required
                />
              </div>
              <div className="col-sm-2">
                <input
                  type="date"
                  name="startDate"
                  value={qual.startDate}
                  onChange={(e) => handleQualificationChange(index, e)}
                  placeholder="Start Date"
                  className="form-control"
                  required
                />
              </div>
              <div className="col-sm-2">
                <input
                  type="date"
                  name="endDate"
                  value={qual.endDate}
                  onChange={(e) => handleQualificationChange(index, e)}
                  placeholder="End Date"
                  className="form-control"
                  required
                />
              </div>
              <div className="col-sm-12 mt-2">
                <button type="button" onClick={() => removeQualification(index)} className="btn btn-sm btn-label-danger">
                  Remove Qualification
                </button>
              </div>
            </div>
          ))}
          <button type="button" onClick={addQualification} className="btn btn-sm btn-label-primary">
            Add Qualification
          </button>
        </div>

        {/* Specializations */}
        <div className="mb-5">
          <h4>Specializations</h4>
          {formData.specializations.map((spec, index) => (
            <div key={index} className="row mb-3">
              <div className="col-sm-6">
                <input
                  type="text"
                  name="specialization"
                  value={spec.specialization}
                  onChange={(e) => handleSpecializationChange(index, e)}
                  placeholder="Specialization"
                  className="form-control"
                  required
                />
              </div>
              <div className="col-sm-3">
                <input
                  type="date"
                  name="startDate"
                  value={spec.startDate}
                  onChange={(e) => handleSpecializationChange(index, e)}
                  placeholder="Start Date"
                  className="form-control"
                  required
                />
              </div>
              <div className="col-sm-3">
                <input
                  type="date"
                  name="endDate"
                  value={spec.endDate}
                  onChange={(e) => handleSpecializationChange(index, e)}
                  placeholder="End Date"
                  className="form-control"
                  required
                />
              </div>
              <div className="col-sm-12 mt-2">
                <button type="button" onClick={() => removeSpecialization(index)} className="btn btn-sm btn-label-danger">
                  Remove Specialization
                </button>
              </div>
            </div>
          ))}
          <button type="button" onClick={addSpecialization} className="btn btn-sm btn-label-primary">
            Add Specialization
          </button>
        </div>

        {/* About */}
        <div className="mb-5">
          <h4>About</h4>
          <textarea
            name="about"
            value={formData.about}
            onChange={handleChange}
            placeholder="About"
            className={`form-control ${errors.about ? 'is-invalid' : ''}`}
            required
          />
          <div className="invalid-feedback">{errors.about}</div>
        </div>

        {/* Submit Button */}
        <div className="d-flex justify-content-end">
          <button className="btn btn-primary" type="submit">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default DoctorForm;
