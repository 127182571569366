import axios from 'axios'
import React from 'react'
import { toast } from 'react-toastify'
import { useAuth } from '../../components/utils/loggedin';
export default function DeleteRole({ role, fetchRoles }) {
    const { token } = useAuth();

    const deleteRole = async () => {
        try {
            const res = await axios.delete(`${process.env.REACT_APP_API_URL}/role/${role?.id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Signature: `${process.env.REACT_APP_API_SIGNATURE}`,
                    Authorization: `Bearer ${token}`
                }
            })
            toast.success("Role deleted successfully");
            document.getElementById('closeModal').click();
            fetchRoles();
        } catch (error) {
            toast.error(error.response.data.message);
        }
    };

    return (
        <div className="modal fade" id="deleteRole" tabIndex={-1} aria-hidden="true">
            <div className="modal-dialog modal-simple modal-enable-otp modal-dialog-centered">
                <div className="modal-content">
                    <button
                        type="button"
                        id="closeModal"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    />
                    <div className="modal-body p-0">
                        <div className="text-center mb-6">
                            <h4 className="mb-2">Delete Role!</h4>
                            <i style={{ fontSize: '50px' }} className="ri-error-warning-line text-danger"></i>
                        </div>
                        <p className="mb-5 text-center">
                            Are you sure you want to delete this role?
                        </p>
                        <div className="col-12 d-flex flex-wrap justify-content-center gap-4 row-gap-4">
                            <button onClick={deleteRole} type="submit" className="btn btn-danger">
                                Delete Role
                            </button>
                            <button
                                type="reset"
                                className="btn btn-outline-secondary"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
