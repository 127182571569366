import React, { useState, useEffect } from 'react';
import AddLocation from './addlocation';
import { useAuth } from '../../../components/utils/loggedin';
import { Helmet } from 'react-helmet';
import axios from 'axios'; // Don't forget to import axios
import { toast } from 'react-toastify'; // Import toast for notifications
import EditLocation from './editlocation';

export default function Location() {
    const { admin } = useAuth();
    const [locations, setLocations] = useState([]); // Initialize as an empty array
    const { token } = useAuth() // Assuming token is stored in localStorage
    const [item, setItem] = useState(null);
    const pages = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalItems, setTotalItems] = useState(0);


    useEffect(() => {
        fetchLocations();
    }, [token, limit]);

    const fetchLocations = async (page = 1) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/location/admin/getall?page=${page}&limit=${limit}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Signature': process.env.REACT_APP_API_SIGNATURE,
                }
            });
            console.log('API Response:', response.data.data); // Log the API response
            setLocations(response.data.locations || []); // Ensure it's always an array
            setTotalPages(response?.data?.pagination?.totalPages || 1);
            setTotalItems(response?.data?.pagination?.totalItems || 0);
            setCurrentPage(page);
        } catch (error) {
            toast.error("Error fetching locations: " + error.message);
        }
    };

    useEffect(() => {
        console.log('Locations state:', locations); // Log the locations state during rendering
    }, [locations]);

    return (
        <>
            <Helmet>
                <title> Manage Locations : BharateClinics</title>
            </Helmet>

            <div className="card">
                <div className="card-datatable table-responsive pt-0">
                    <div
                        id="DataTables_Table_0_wrapper"
                        className="dataTables_wrapper dt-bootstrap5 no-footer"
                    >
                        <div className="card-header flex-column flex-md-row border-bottom">
                            <div className="head-label text-center">
                                <h5 className="card-title mb-0">Locations</h5>
                            </div>
                            <div className="dt-action-buttons text-end pt-3 pt-md-0">
                                <div className="dt-buttons btn-group flex-wrap">
                                    {admin?.role?.permissions?.ADMIN_PANEL?.location?.create_location &&
                                        <button
                                            className="btn btn-secondary create-new btn-primary waves-effect waves-light"
                                            tabIndex={0}
                                            aria-controls="DataTables_Table_0"
                                            type="button"
                                            data-bs-target="#offcanvasAddAddress"
                                            data-bs-toggle="offcanvas"
                                        >
                                            <span>
                                                <i className="ri-add-line ri-16px me-sm-2" />{" "}
                                                <span>Add New Address</span>
                                            </span>
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-6 mt-5 mt-md-0">
                                <div className="dataTables_length" id="DataTables_Table_0_length">
                                    <label>
                                        Show{" "}
                                        <select
                                            name="DataTables_Table_0_length"
                                            aria-controls="DataTables_Table_0"
                                            className="form-select form-select-sm"
                                            value={limit}
                                            onChange={(e) => setLimit(e.target.value)}
                                        >
                                            <option value={7}>7</option>
                                            <option value={10}>10</option>
                                            <option value={25}>25</option>
                                            <option value={50}>50</option>
                                            <option value={75}>75</option>
                                            <option value={100}>100</option>
                                        </select>{" "}
                                        entries
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-striped table-bordered table-hover">
                                <thead className="thead-dark">
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Address</th>
                                        <th scope="col">City</th>
                                        <th scope="col">State</th>
                                        <th scope="col">Zip Code</th>
                                        <th scope="col">Latitude</th>
                                        <th scope="col">Longitude</th>
                                        <th scope='col'>Status</th>
                                        <th scope='col'>Action </th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {locations && locations.length > 0 ? (
                                        locations.map((loc, index) => (
                                            <tr key={loc.id}>
                                                <td>{(currentPage - 1) * pages + index + 1}</td>
                                                <td>{loc.address || 'N/A'}</td>
                                                <td>{loc.city || 'N/A'}</td>
                                                <td>{loc.state || 'N/A'}</td>
                                                <td>{loc.zip || 'N/A'}</td>
                                                <td>{loc.latitude || 'N/A'}</td>
                                                <td>{loc.longitude || 'N/A'}</td>
                                                <td>{loc.status || 'N/A'}</td>
                                                <td><button button
                                                    onClick={() => setItem(loc)}
                                                    data-bs-target="#editLocation"
                                                    data-bs-toggle="offcanvas"
                                                    className="btn text-primary"
                                                >
                                                    <i className='ri-edit-line me-2'></i>
                                                </button></td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="9" className="text-center">
                                                No locations available
                                            </td>
                                        </tr>
                                    )}

                                </tbody>
                                <EditLocation fetchLocations={fetchLocations} item={item} />

                            </table>
                        </div>
                        <AddLocation locations={locations} fetchLocations={fetchLocations} />
                    </div>
                </div>
            </div>
        </>
    );
}
