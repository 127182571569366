import React, { useEffect, useState } from "react";
import { useAuth } from "../../../components/utils/loggedin";
import axios from "axios";
import { toast } from "react-toastify";
import validator from "validator";

export default function Cards({ admin }) {
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [notfound, setNotFound] = useState(false);
  const [activeCards, setActiveCards] = useState(0)
  const [searchByUsername, setSearchByUsername] = useState("")
  const { token } = useAuth();
  const pages = 10;

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);

  // Pagination states

  const [startRange, setStartRange] = useState("");
  const [endRange, setEndRange] = useState("");
  const [errors, setErrors] = useState({
    startRange: "",
    endRange: "",
  });

  const validateInputs = () => {
    let isValid = true;
    const newErrors = { startRange: "", endRange: "", };

    if (
      !validator.isLength(startRange, { min: 10, max: 10 }) ||
      !validator.isLength(endRange, { min: 10, max: 10 })
    ) {
      newErrors.startRange = "Start Range should be in the format BH00000001";
      newErrors.endRange = "End Range should be in the format BH99999999";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const fetchCards = async (page = 1) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/healthcard/admin/${admin.id}/statistics?page=${page}&limit=${limit}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Signature: process.env.REACT_APP_API_SIGNATURE,
          },
        }
      );
      if (response.status === 200) {
        setCards(response.data.data);
        setActiveCards(response.data.statistics)
        setCurrentPage(page);
        setTotalPages(response?.data?.pagination.totalPages);

        setLoading(false);
      } else if (response.status === 404) {
        setCards([]);
        setNotFound(true);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setNotFound(true);
      setLoading(false);
    }
  };

  const activatedCards = activeCards.activatedCardsCount;
  const notActivatedCards = activeCards.notActivatedCardsCount;
  console.log(activatedCards);

  const renderPagination = () => {
    const pageNumbers = [];
    const maxPageButtons = 5;
    let startPage = Math.max(currentPage - Math.floor(maxPageButtons / 2), 1);
    let endPage = Math.min(startPage + maxPageButtons - 1, totalPages);

    if (endPage - startPage < maxPageButtons - 1) {
      startPage = Math.max(endPage - maxPageButtons + 1, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
          <button className="page-link" onClick={() => handlePageChange(i)}>
            {i}
          </button>
        </li>
      );
    }

    const handlePageChange = (page) => {
      if (page < 1 || page > totalPages) return;
      fetchCards(page);
    };

    return (
      <ul className="pagination">
        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
          <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
            <i className="tf-icon ri-arrow-left-s-line ri-20px" />
          </button>
        </li>
        {startPage > 1 && (
          <>
            <li className="page-item">
              <button className="page-link" onClick={() => handlePageChange(1)}>1</button>
            </li>
            <li className="page-item disabled"><span className="page-link">...</span></li>
          </>
        )}
        {pageNumbers}
        {endPage < totalPages && (
          <>
            <li className="page-item disabled"><span className="page-link">...</span></li>
            <li className="page-item">
              <button className="page-link" onClick={() => handlePageChange(totalPages)}>{totalPages}</button>
            </li>
          </>
        )}
        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
          <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
            <i className="tf-icon ri-arrow-right-s-line ri-20px" />
          </button>
        </li>
      </ul>
    );
  };


  useEffect(() => {
    fetchCards();
  }, [admin.id, token, limit, searchByUsername]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateInputs()) return;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/healthcard/generate-admin-card`,
        {
          start_range: startRange,
          end_range: endRange,
          admin_id: admin.id,
        },
        {
          headers: {
            Signature: process.env.REACT_APP_API_SIGNATURE,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 201) {
        toast.success(response.data.message);
        document.getElementById('closeCanvas').click()
        setStartRange("");
        setEndRange("");
        fetchCards();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.message
        : "An error occurred";
      console.log(errorMessage);
      toast.error(errorMessage);
    }
  };

  // Calculate the number of activated and not activated cards
  // const activatedCards = cards.filter(card => card.activated).length;
  // const notActivatedCards = cards.length - activatedCards;

  // // Pagination logic
  // const totalPages = Math.ceil(cards.length / cardsPerPage);

  // const handlePageChange = (pageNumber) => {
  //   setCurrentPage(pageNumber);
  // };

  useEffect(() => {
    if (currentPage > 1 && currentPage > totalPages) {
      setCurrentPage(1);
    }
  }, [currentPage, totalPages]);

  return (
    <>
      {/* Current Plan */}

      <div className="card mb-6">
        <div className="card h-100">

          <div className="card-body d-flex flex-wrap gap-4">
            <div className="d-flex align-items-center gap-3">
              <div className="avatar">
                <div className="avatar-initial bg-label-primary rounded ">
                  <i className="ri-check-line ri-24px" />
                </div>
              </div>
              <div className="card-info text-success">

                <h5 className="mb-0">{activatedCards}</h5>

                <p className="mb-0">Activated Cards</p>
              </div>
            </div>

            <div className="d-flex align-items-center gap-3">
              <div className="avatar">
                <div className="avatar-initial bg-label-info rounded">
                  <i class="ri-close-line"></i>
                </div>
              </div>
              <div className="card-info text-danger">
                <h5 className="mb-0">{notActivatedCards}</h5>

                <p className="mb-0">Not Activated Cards</p>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex flex-column flex-md-row justify-content-between align-items-center p-4 gap-3 border border-bottom-5">
          <label className="d-flex flex-column flex-md-row align-items-center mb-0">
            Show
            <select
              name="DataTables_Table_0_length"
              aria-controls="DataTables_Table_0"
              className="form-select form-select-sm ms-0 ms-md-2 me-0 me-md-2 mt-2 mt-md-0"
              value={limit}
              onChange={(e) => setLimit(e.target.value)}
              style={{ width: "auto" }}
            >

              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={75}>75</option>
              <option value={100}>100</option>
            </select>
            entries
          </label>
          <div className="add-new mt-3 mt-md-0">
            <button
              type="button"
              className="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#addNewCCModal"
            >
              Generate Cards
            </button>
          </div>
        </div>




        {/* Cards Table */}
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Card Number</th>
                <th>Type</th>
                <th>Status</th>
                <th>Created At</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="5">Loading...</td>
                </tr>
              ) : notfound ? (
                <tr>
                  <td colSpan="5">No Data Found</td>
                </tr>
              ) : (
                cards.map((card, index) => (
                  <tr key={index}>
                    <td>{(currentPage - 1) * pages + index + 1}</td>
                    <td>{card.card_number}</td>
                    <td>{card.type}</td>
                    <td
                      className={card.activated ? "text-success" : "text-danger"}
                    >
                      {card.activated ? (
                        <span className="badge bg-label-success rounded-pill">
                          Activated
                        </span>
                      ) : (
                        <span className="badge bg-label-danger rounded-pill">
                          Not Activated
                        </span>
                      )}
                    </td>
                    <td>
                      {new Date(card.created_at).toLocaleDateString("en-us", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          <div className="row mt-3">
            <nav aria-label="Page navigation">
              {renderPagination()}
            </nav>
          </div>
        </div>



        {/* smart card model for sales and doctor admin  */}

        <div
          className="modal fade"
          id="addNewCCModal"
          tabIndex={-1}
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered1 modal-simple modal-add-new-cc">
            <div className="modal-content">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
              <div className="modal-body p-0">
                <div className="text-center mb-6">
                  <h4 className="mb-2">Add New Smart Health Card</h4>
                  <p>Add new card to avail benefits</p>
                </div>
                <form
                  id="addNewCCForm"
                  className="row g-5"
                  onSubmit={handleSubmit}
                >
                  <div className="col-6">
                    <div className="input-group input-group-merge">
                      <div className="form-floating form-floating-outline">
                        <input
                          id="startRange"
                          name="startRange"
                          className={`form-control ${errors.startRange ? "is-invalid" : ""
                            }`}
                          type="text"
                          placeholder="Start Range (e.g. BH00000001)"
                          value={startRange}
                          onChange={(e) => setStartRange(e.target.value)}
                          aria-describedby="modalAddCard1"
                        />
                        <label htmlFor="startRange">Start Range</label>
                        {errors.startRange && (
                          <div className="invalid-feedback">
                            {errors.startRange}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="input-group input-group-merge">
                      <div className="form-floating form-floating-outline">
                        <input
                          id="endRange"
                          name="endRange"
                          className={`form-control ${errors.endRange ? "is-invalid" : ""
                            }`}
                          type="text"
                          placeholder="End Range (e.g. BH99999999)"
                          value={endRange}
                          onChange={(e) => setEndRange(e.target.value)}
                          aria-describedby="modalAddCard2"
                        />
                        <label htmlFor="endRange">End Range</label>
                        {errors.endRange && (
                          <div className="invalid-feedback">
                            {errors.endRange}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-12 d-flex flex-wrap justify-content-center gap-4 row-gap-4">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                    <button
                      type="reset"
                      className="btn btn-outline-secondary btn-reset"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="closeCanvas"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*/ Cards Address */}
    </>
  );
}
