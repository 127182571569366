import React, { useRef, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../utils/loggedin";
import axios from "axios";

export default function Header({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [toggleMenu, setToggleMenu] = useState(false);
  const { token, logout, admin } = useAuth();
  const [adminData, setAdminData] = useState({});
  const menuRef = useRef(null);

  useEffect(() => {
    const fetchAdminData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/getadmin`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              Signature: process.env.REACT_APP_API_SIGNATURE,
            },
          }
        );

        // Ensure response and response.data are defined before accessing
        if (response && response.status === 200 && response.data) {
          setAdminData(response.data);
        } else {
          console.error("Unexpected response structure:", response);
        }
      } catch (error) {
        console.error("Error fetching admin data:", error);
        if (error.response && error.response.data) {
          console.error("API Error:", error.response.data.message);
        }
      }
    };

    if (token) {
      fetchAdminData();
    }
  }, [token]);
  // Only re-run if token changes


  const handleToggle = () => {
    setToggleMenu(!toggleMenu);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setToggleMenu(false); // Close the menu if click is outside
    }
  };

  useEffect(() => {
    // Add event listener for clicks
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Clean up the event listener on component unmount
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`light-style layout-navbar-fixed layout-compact layout-menu-100vh layout-menu-${toggleMenu ? "expanded" : "fixed"
        }`}
      dir="ltr"
      data-theme="theme-default data-assets-path=assets/"
      data-template="vertical-menu-template"
      data-style="light"
    >
      <div className="layout-wrapper layout-content-navbar  ">
        <div className="layout-container" ref={menuRef}>
          {/* Menu */}
          <aside
            id="layout-menu"
            className="layout-menu menu-vertical menu bg-menu-theme"
          >
            <div className="app-brand demo ">
              <Link to="/" className="app-brand-link">
                <span className="app-brand-logo demo">
                  <img src="../img/logo.png" height={80} alt="" />
                </span>
                <span
                  className={`app-brand-text demo menu-text fw-semibold ms-2 ${window.innerWidth < 768 ? "d-none" : ""
                    }`}
                >
                  Bharat eClinics
                </span>
              </Link>
              <button
                onClick={handleToggle}
                href="javascript:void(0);"
                className="btn layout-menu-toggle menu-link text-large ms-auto d-block d-md-none"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.47365 11.7183C8.11707 12.0749 8.11707 12.6531 8.47365 13.0097L12.071 16.607C12.4615 16.9975 12.4615 17.6305 12.071 18.021C11.6805 18.4115 11.0475 18.4115 10.657 18.021L5.83009 13.1941C5.37164 12.7356 5.37164 11.9924 5.83009 11.5339L10.657 6.707C11.0475 6.31653 11.6805 6.31653 12.071 6.707C12.4615 7.09747 12.4615 7.73053 12.071 8.121L8.47365 11.7183Z"
                    fill-opacity="0.9"
                  ></path>
                  <path
                    d="M14.3584 11.8336C14.0654 12.1266 14.0654 12.6014 14.3584 12.8944L18.071 16.607C18.4615 16.9975 18.4615 17.6305 18.071 18.021C17.6805 18.4115 17.0475 18.4115 16.657 18.021L11.6819 13.0459C11.3053 12.6693 11.3053 12.0587 11.6819 11.6821L16.657 6.707C17.0475 6.31653 17.6805 6.31653 18.071 6.707C18.4615 7.09747 18.4615 7.73053 18.071 8.121L14.3584 11.8336Z"
                    fill-opacity="0.4"
                  ></path>
                </svg>
              </button>
            </div>
            <div className="menu-inner-shadow" />
            <ul className="menu-inner py-1 overflow-auto no-scrollbar" style={{ height: "90vh" }}>
              <li
                className={`menu-item ${location.pathname === "/" ? "active" : ""
                  }`}
              >
                <Link to="/" className="menu-link">
                  <i class="menu-icon tf-icons ri-dashboard-line"></i>
                  <div data-i18n="Dashboard">Dashboard</div>
                </Link>
              </li>
              {admin?.role?.permissions?.ADMIN_PANEL?.sales?.manage_salesreps && (
                <li
                  className={`menu-item ${location.pathname === "/sales" || location.pathname === "/sales/view" ? "active" : ""
                    }`}
                >
                  <Link to="/sales" className="menu-link">
                    <i className="menu-icon tf-icons ri-line-chart-line"></i>
                    <div data-i18n="Manage Sales">Manage Sales Reps</div>
                  </Link>
                </li>
              )}
              {(admin?.role?.permissions?.ADMIN_PANEL?.doctor?.manage_doctor &&
                <li
                  className={`menu-item ${(location.pathname === "/admins/doctors" ||
                    location.pathname === "/admins/doctors/view") &&
                    "active"
                    }`}
                >
                  <Link to="/admins/doctors" className="menu-link">
                    <i className="menu-icon tf-icons ri-stethoscope-line"></i>
                    <div data-i18n="Manage Doctors">Manage Doctors</div>
                  </Link>
                </li>
              )}
              {admin?.role?.permissions?.ADMIN_PANEL?.sales?.manage_sales && (

                <li
                  className={`menu-item ${location.pathname === "/admins/salesreps" ||
                    location.pathname === "/admins/salesreps/view"
                    ? "active"
                    : ""
                    }`}
                >
                  <Link to="/admins/salesreps" className="menu-link">
                    <i className="menu-icon tf-icons ri-line-chart-line"></i>
                    <div data-i18n="Manage Sales">Manage Sales</div>
                  </Link>
                </li>
              )}
              {admin?.role?.permissions?.ADMIN_PANEL?.patient?.manage_patient && (
                <li
                  className={`menu-item ${location.pathname === "/admins/patients" ||
                    location.pathname === "/admins/patient/view"
                    ? "active"
                    : ""
                    }`}
                >
                  <Link to="/admins/patients" className="menu-link">
                    <i className=" menu-icon tf-icons ri-user-line"></i>
                    <div data-i18n=" Manage Patients">Manage Patients</div>
                  </Link>
                </li>
              )}
              {admin?.role?.permissions?.ADMIN_PANEL?.teams?.manage_teams && (
                <li
                  className={`menu-item ${location.pathname === "/admins" ||
                    location.pathname === "/admins/view"
                    ? "active"
                    : ""
                    }`}
                >
                  <Link to="/admins" className="menu-link">
                    <i class="menu-icon ri-shield-user-line"></i>
                    <div data-i18n="Manage Teams">Manage Teams</div>
                  </Link>
                </li>
              )}
              {admin?.role?.permissions?.ADMIN_PANEL?.sales?.generate_card_salesrep && (
                <li
                  className={`menu-item ${location.pathname === "/cards" ? "active" : ""
                    }`}
                >
                  <Link to="/cards" className="menu-link">
                    <i className="menu-icon tf-icons ri-id-card-line"></i>
                    <div data-i18n="Manage Cards">Manage Cards</div>
                  </Link>
                </li>
              )}
              {admin?.role?.permissions?.ADMIN_PANEL?.sales?.allotcard_salesrep && (
                <li
                  className={`menu-item ${location.pathname === "/sales/allotedcards" ? "active" : ""
                    }`}
                >
                  <Link to="/sales/allotedcards" className="menu-link">
                    <i class="ri-id-card-fill me-2"></i>
                    <div data-i18n="Manage Alloted Cards">Manage Alloted Cards</div>
                  </Link>
                </li>
              )}
              {adminData.admin_type === "superadmin" && (
                <li
                  className={`menu-item ${location.pathname === "/admins/digitalcards" && "active"}`}
                >
                  <Link to="/admins/digitalcards" className="menu-link">
                    <i class="ri-id-card-line me-2"></i>
                    <div data-i18n=" Manage DigitalCards">Manage DigitalCards</div>
                  </Link>
                </li>
              )}
              {admin?.role?.permissions?.ADMIN_PANEL?.contact?.manage_contact && (
                <li
                  className={`menu-item ${location.pathname === "/admins/enquiry" && "active"}`}
                >
                  <Link to="/admins/enquiry" className="menu-link">
                    <i class="ri-contacts-book-line me-2"></i>
                    <div data-i18n=" Manage Enquiry">Manage Enquiry</div>
                  </Link>
                </li>
              )}
              {admin?.role?.permissions?.ADMIN_PANEL?.testimonial?.manage_testimonial && (
                <li
                  className={`menu-item ${location.pathname === "/admins/testimonials" && "active"}`}
                >
                  <Link to="/admins/testimonials" className="menu-link">
                    <i class="ri-terminal-window-line me-2"></i>
                    <div data-i18n=" Manage Testimonials">Manage Testimonials</div>
                  </Link>
                </li>
              )}
              {admin?.role?.permissions?.ADMIN_PANEL?.location?.manage_location && (
                <li
                  className={`menu-item ${location.pathname === "/admins/locations" && "active"}`}
                >
                  <Link to="/admins/locations" className="menu-link">
                    <i class="ri-map-pin-line me-2"></i>
                    <div data-i18n=" Manage Locations">Manage Locations</div>
                  </Link>
                </li>
              )}
              {admin?.role?.permissions?.ADMIN_PANEL?.email_template?.manage_email_template && (
                <li
                  className={`menu-item ${location.pathname === "/admins/emailtemplates" && "active"}`}
                >
                  <Link to="/admins/emailtemplates" className="menu-link">
                    <i class="ri-mail-line me-2"></i>
                    <div data-i18n=" Manage Email Templates">Manage Email Templates</div>
                  </Link>
                </li>
              )}
              {admin?.role?.permissions?.ADMIN_PANEL?.sms_template?.manage_sms_template && (
                <li
                  className={`menu-item ${location.pathname === "/admins/smstemplates" && "active"}`}
                >
                  <Link to="/admins/smstemplates" className="menu-link">
                    <i class="ri-mail-line me-2"></i>
                    <div data-i18n=" Manage SMS Templates">Manage SMS Templates</div>
                  </Link>
                </li>
              )}
              {adminData.admin_type === "superadmin" && (
                <li
                  className={`menu-item ${location.pathname === "/admins/roles-and-permission" && "active"}`}
                >
                  <Link to="/admins/roles-and-permission" className="menu-link">
                    <i class="ri-remote-control-line me-2"></i>
                    <div data-i18n=" Manage Testimonials">Manage Roles</div>
                  </Link>
                </li>
              )}
              {admin?.role?.permissions?.CLINIC_PANEL?.clinic?.manage_clinics && (
                <li
                  className={`menu-item ${location.pathname === "/clinics" ? "active" : ""
                    }`}
                >
                  <Link to="/clinics" className="menu-link">
                    <i className="menu-icon tf-icons ri-hospital-line"></i>
                    <div data-i18n="Manage Clinics">Manage Clinics</div>
                  </Link>
                </li>
              )}


              {admin?.role?.permissions?.DOCTOR_PANEL?.doctor?.manage_doctors && (
                <li
                  className={`menu-item ${location.pathname === "/doctors" || location.pathname === "/admins/doctors/view" || location.pathname === "/doctors/add" ? "active" : ""
                    }`}
                >
                  <Link to="/doctors" className="menu-link">
                    <i className="menu-icon tf-icons ri-stethoscope-line"></i>
                    <div data-i18n="Manage Doctors">Manage Doctors</div>
                  </Link>
                </li>
              )}


              {/* manage admin panel starts here */}
              {/* {adminData.admin_type === "superadmin" && (
                <li
                  className={`menu-item ${
                    location.pathname === "/manageadmin" ? "active" : ""
                  }`}
                >
                  <Link to="/manageadmin" className="menu-link">
                    <i className="menu-icon tf-icons ri-user-settings-line"></i>
                    <div data-i18n="Admins">Mangeadmin</div>
                  </Link>
                </li>
              )} */}


              {(admin?.role?.permissions?.ADMIN_PANEL?.clinic?.manage_clinic &&
                <li
                  className={`menu-item ${(location.pathname === "/admins/clinics" ||
                    location.pathname === "/admins/clinics/view") &&
                    "active"
                    }`}
                >
                  <Link to="/admins/clinics" className="menu-link">
                    <i className="menu-icon tf-icons ri-hospital-line"></i>
                    <div data-i18n="Manage Clinics">Manage Clinics</div>
                  </Link>
                </li>
              )}







              {/* {adminData.admin_type === "superadmin" && (
                <li
                  className={`menu-item ${location.pathname === "/admins/faqs" && "active"}`}
                >
                  <Link to="/admins/faqs" className="menu-link">
                    <i className=" menu-icon tf-icons ri-user-line"></i>
                    <div data-i18n=" Manage FAQ">Manage FAQ</div>
                  </Link>
                </li>
              )} */}
            </ul>
          </aside>
          {/* / Menu */}
          {/* Layout container */}
          <div className="layout-page">
            {/* Navbar */}

            <nav className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme">
              <div className="layout-menu-toggle navbar-nav align-items-xl-center me-4 me-xl-0 d-xl-none">
                <button
                  onClick={handleToggle}
                  className="btn nav-item nav-link px-0 me-xl-6"
                >
                  <i className="ri-menu-fill ri-22px" />
                </button>
              </div>
              {/* Toggle visibility based on toggleMenu */}
              <div
                className={`navbar-nav-right d-flex align-items-center ${toggleMenu ? 'show' : 'collapse'}`}
                id="navbar-collapse"
              >
                <ul className="navbar-nav flex-row align-items-center ms-auto">
                  {/* User Dropdown */}
                  <li className="nav-item navbar-dropdown dropdown-user dropdown">
                    <a
                      className="nav-link dropdown-toggle hide-arrow"
                      href="#"
                      data-bs-toggle="dropdown"
                    >
                      <div className="avatar avatar-online">
                        <img
                          src={adminData.profile_img ? adminData.profile_img : "./img/avatars/5.png"}
                          alt=""
                          className="rounded-circle"
                        />
                      </div>
                    </a>
                    <ul className="dropdown-menu dropdown-menu-end">
                      <li>
                        <div className="dropdown-item">
                          <div className="d-flex">
                            <div className="flex-shrink-0 me-2">
                              <div className="avatar avatar-online">
                                <img
                                  src={adminData.profile_img ? adminData.profile_img : "./img/avatars/5.png"}
                                  alt=""
                                  className="rounded-circle"
                                />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <span className="fw-medium d-block small">
                                {adminData.username}
                              </span>
                              <small className="text-muted">Admin</small>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="dropdown-divider" />
                      </li>
                      {admin?.role?.permissions?.ADMIN_PANEL?.profile?.view_account && (
                        <Link className="dropdown-item" to="/profile">
                          <i className="ri-user-3-line ri-22px me-3" />
                          <span className="align-middle">My Profile</span>
                        </Link>
                      )}
                      <li>
                        <div className="dropdown-divider" />
                      </li>
                      <li>
                        <div className="d-grid px-4 pt-2 pb-1">
                          <button
                            className="btn btn-sm btn-danger d-flex"
                            onClick={() => {
                              logout();
                              navigate("/login");
                            }}
                          >
                            <small className="align-middle">Logout</small>
                            <i className="ri-logout-box-r-line ms-2 ri-16px" />
                          </button>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </nav>
            {/* / Navbar */}
            {/* Content wrapper */}
            <div className="mt-10 content-wrapper">
              {/* Content */}
              <div className="container-xxl flex-grow-1 container-p-y">
                {children}
              </div>
              {/* / Content */}
              {/* Footer */}
              <footer className="content-footer footer bg-footer-theme">
                <div className="container-xxl">
                  <div className="footer-container d-flex align-items-center justify-content-between py-4 flex-md-row flex-column">
                    <div className="text-body text-centermb-2 mb-md-0">
                      Copyright © 2024, Bharat eClinics
                    </div>
                  </div>
                </div>
              </footer>
              {/* / Footer */}
              <div className="content-backdrop fade" />
            </div>
            {/* Content wrapper */}
          </div>
          {/* / Layout page */}
        </div>
        {/* Overlay */}
        <div className="layout-overlay layout-menu-toggle" />
        {/* Drag Target Area To SlideIn Menu On Small Screens */}
        <div className="drag-target" />
      </div >
    </div >
  );
}
