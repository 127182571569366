import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../components/utils/loggedin';
import { Helmet } from 'react-helmet';

export default function AllotedCards() {
    const [cards, setCards] = useState([]);
    const [loading, setLoading] = useState(true);
    const { token } = useAuth()

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/healthcard/activatedcardforsalesrep`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            Signature: process.env.REACT_APP_API_SIGNATURE,
                        },
                    }
                ) // Replace with your API endpoint
                setCards(response.data.cards);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        }

        fetchData();
    }, []);

    if (loading) {
        return (
            <div className="loading-container">
                <p>Loading...</p>
            </div>
        );
    }
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = String(date.getFullYear()).slice(-2); // Get last two digits of the year

        return `${day}/${month}/${year}`;
    };

    return (
        <>
            <Helmet>
                <title>Alloted Cards - BharateClinics</title>
            </Helmet>

            <div className="card">
                <div className="card-header border-bottom d-flex justify-content-between">
                    <h5 className="card-title mb-0">Card Details</h5>

                </div>
                <div className="card-datatable table-responsive">
                    <div
                        id="DataTables_Table_0_wrapper"
                        className="dataTables_wrapper dt-bootstrap5 no-footer"
                    >
                        <div
                            className="modal fade"
                            id="addNewCCModal"
                            tabIndex={-1}
                            aria-hidden="true"
                        >
                            <div className="modal-dialog modal-dialog-centered1 modal-simple modal-add-new-cc">
                                <div className="modal-content">
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    />
                                    <div className="modal-body p-0">
                                        <div className="text-center mb-6">
                                            <h4 className="mb-2">Add New Smart Health Card</h4>
                                            <p>Add new card to avail benefits</p>
                                        </div>
                                        <form
                                            id="addNewCCForm"
                                            className="row g-5"

                                        >
                                            {/* Add form fields here */}
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <table className="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Card Number</th>
                                    <th>Username</th>
                                    <th>Name</th>
                                    <th>Activated</th>
                                    <th>Validity</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td colSpan="5">Loading...</td>
                                    </tr>
                                ) : (
                                    cards.length > 0 ? (
                                        cards.map((card, index) => (
                                            <tr key={card.id}>
                                                <td>{card.card_number}</td>
                                                <td>{card.activated_by_salesrep.username || "DefaultUsername"}</td>
                                                <td>{card.activated_by_salesrep.first_name} {card.activated_by_salesrep.last_name}</td>
                                                <td>
                                                    {card.activated ? (
                                                        <span className="badge bg-label-success rounded-pill">
                                                            Activated
                                                        </span>
                                                    ) : (
                                                        <span className="badge bg-label-danger rounded-pill">
                                                            Not Activated
                                                        </span>
                                                    )}
                                                </td>
                                                <td>{card.validity ? formatDate(card.validity) : 'N/A'}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="5" className="text-center">
                                                No data available
                                            </td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </>
    );
}
