import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export default function AllSalesReps() {
    const [salesreps, setSalesReps] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchName, setSearchName] = useState('');
    const [searchUsername, setSearchUsername] = useState('');
    const [searchAdmin, setSearchAdmin] = useState('');
    const [searchPhone, setSearchPhone] = useState('');
    const [filterOnboarded, setFilterOnboarded] = useState('All');
    const [searchStatus, setSearchStatus] = useState('All');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const navigate = useNavigate();
    const pages = 10;

    const fetchSalesReps = (page = 1) => {
        setLoading(true); // Set loading to true when fetching
        axios.get(`${process.env.REACT_APP_API_URL}/salesrep/getallsalesreps?page=${page}&limit=${limit}&username=${searchUsername}&name=${searchName}&phone=${searchPhone}&adminName=${searchAdmin}&verified=${searchStatus}&onboarded=${filterOnboarded}`, {
            headers: {
                "Signature": process.env.REACT_APP_API_SIGNATURE
            }
        })
            .then(response => {
                const salesRepsData = response.data.salesReps || [];
                setSalesReps(salesRepsData);
                setTotalPages(response?.data?.pagination?.totalPages || 1);
                setTotalItems(response?.data?.pagination?.totalItems || 0);
                setCurrentPage(page);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching salesreps:', error);
                setSalesReps([])
                setLoading(false);
            });
    }

    const renderPagination = () => {
        if (totalItems <= limit) return null; // No need for pagination if total items are less than or equal to the limit

        const totalPages = Math.ceil(totalItems / limit); // Calculate total pages based on the limit
        const pageNumbers = [];
        const maxPageButtons = 5; // The number of visible page buttons
        let startPage = Math.max(currentPage - Math.floor(maxPageButtons / 2), 1);
        let endPage = Math.min(startPage + maxPageButtons - 1, totalPages);

        if (endPage - startPage < maxPageButtons - 1) {
            startPage = Math.max(endPage - maxPageButtons + 1, 1);
        }

        // Generate page number buttons
        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(i)}>
                        {i}
                    </button>
                </li>
            );
        }

        return (
            <ul className="pagination">
                {/* Previous button */}
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button
                        className="page-link"
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        <i className="tf-icon ri-arrow-left-s-line ri-20px" />
                    </button>
                </li>

                {/* Display first page and ellipsis if necessary */}
                {startPage > 1 && (
                    <>
                        <li className="page-item">
                            <button className="page-link" onClick={() => handlePageChange(1)}>
                                1
                            </button>
                        </li>
                        {startPage > 2 && (
                            <li className="page-item disabled">
                                <span className="page-link">...</span>
                            </li>
                        )}
                    </>
                )}

                {/* Page numbers */}
                {pageNumbers}

                {/* Display last page and ellipsis if necessary */}
                {endPage < totalPages && (
                    <>
                        {endPage < totalPages - 1 && (
                            <li className="page-item disabled">
                                <span className="page-link">...</span>
                            </li>
                        )}
                        <li className="page-item">
                            <button className="page-link" onClick={() => handlePageChange(totalPages)}>
                                {totalPages}
                            </button>
                        </li>
                    </>
                )}

                {/* Next button */}
                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button
                        className="page-link"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    >
                        <i className="tf-icon ri-arrow-right-s-line ri-20px" />
                    </button>
                </li>
            </ul>
        );
    };

    useEffect(() => {
        fetchSalesReps();
    }, [limit, searchUsername, searchName, searchStatus, searchPhone, searchAdmin, searchStatus, filterOnboarded]);

    const handlePageChange = (page) => {
        if (page < 1 || page > totalPages) return;
        fetchSalesReps(page);
    };

    function ViewSalesRep(salesrepId) {
        if (!salesrepId) {
            navigate('/admins/salesreps');
        }
        navigate('/admins/salesreps/view', { state: { salesrepId } });
    }

    const exportToExcel = () => {
        // Create worksheet data
        const worksheetData = salesreps.map((salesrep, index) => ({
            "S.No": (currentPage - 1) * limit + index + 1,
            "Username": salesrep.username === "" ? "BHSRXXXX" : salesrep.username,
            "SalesAdmin": salesrep.sales_admin_name,
            "Name": `${salesrep.first_name} ${salesrep.last_name}`,
            "Phone": salesrep.phone,
            "Status": salesrep.verified ? "Verified" : "Not Verified",
            "Onboarded": salesrep.onboarded ? "Onboarded" : "Not Onboarded",
        }));

        // Define the worksheet and workbook
        const worksheet = XLSX.utils.json_to_sheet(worksheetData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'SalesReps');

        // Generate Excel file and trigger download
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(data, 'SalesReps.xlsx');
    };

    return (
        <>
            <Helmet>
                <title>Manage SalesReps Page</title>
            </Helmet>

            {/* Show per page dropdown */}
            <div className="card">
                <h5 className="card-title p-4 mb-0">Sales Representative</h5>

                <div className="d-flex flex-column flex-md-row justify-content-between align-items-center p-4 gap-3">
                    <label className="d-flex align-items-center mb-2 mb-md-0">
                        Show
                        <select
                            className="form-select form-select-sm ms-2 me-2"
                            value={limit}
                            onChange={(e) => setLimit(parseInt(e.target.value, 10))}
                            style={{ width: "auto" }}
                        >
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={75}>75</option>
                            <option value={100}>100</option>
                        </select>
                        entries
                    </label>
                    <button className="btn btn-primary" onClick={exportToExcel}>
                        Export to Excel
                    </button>
                </div>

                <div className="card-datatable table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Username</th>
                                <th>SalesAdmin</th>
                                <th>Name</th>
                                <th>Phone</th>
                                <th>Status</th>
                                <th>Onboarded</th>
                                <th>Action</th>
                            </tr>
                            {/* Search Inputs */}
                            <tr>
                                <td></td>
                                <td>
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder="Search by username"
                                        value={searchUsername}
                                        onChange={(e) => setSearchUsername(e.target.value)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder="Search by Admin name"
                                        value={searchAdmin}
                                        onChange={(e) => setSearchAdmin(e.target.value)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder="Search by name"
                                        value={searchName}
                                        onChange={(e) => setSearchName(e.target.value)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder="Search by phone"
                                        value={searchPhone}
                                        onChange={(e) => setSearchPhone(e.target.value)}
                                    />
                                </td>
                                <td>
                                    <select
                                        className="form-select form-select-sm"
                                        value={searchStatus}
                                        onChange={(e) => setSearchStatus(e.target.value)}
                                    >
                                        <option value="All">All</option>
                                        <option value="true">Verified</option>
                                        <option value="false">Not Verified</option>
                                    </select>
                                </td>
                                <td>
                                    <select
                                        className="form-select form-select-sm"
                                        value={filterOnboarded}
                                        onChange={(e) => setFilterOnboarded(e.target.value)}
                                    >
                                        <option value="All">All</option>
                                        <option value="true">Onboarded</option>
                                        <option value="false">Not Onboarded</option>
                                    </select>
                                </td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                <tr>
                                    <td colSpan="8">Loading...</td>
                                </tr>
                            ) : salesreps.length === 0 ? (
                                <tr>
                                    <td colSpan="8" className="text-center text-muted">
                                        No data found
                                    </td>
                                </tr>
                            ) : (
                                salesreps.map((salesreps, index) => (
                                    <tr key={index}>
                                        <td>{(currentPage - 1) * pages + index + 1}</td>
                                        <td>{salesreps.username === "" ? "BHSRXXXX" : salesreps.username}</td>
                                        <td>{salesreps.sales_admin_name}</td>
                                        <td>{salesreps.first_name} {salesreps.last_name}</td>
                                        <td>{salesreps.phone}</td>
                                        <td className={salesreps.verified ? "text-success" : "text-danger"}>
                                            {salesreps.verified ? <span className="badge bg-label-success rounded-pill">Verified</span> : <span className="badge bg-label-danger rounded-pill">Not Verified</span>}
                                        </td>
                                        <td className={salesreps.onboarded ? "text-success" : "text-warning"}>
                                            {salesreps.onboarded ? <span className="badge bg-label-success rounded-pill">Onboarded</span> : <span className="badge bg-label-warning rounded-pill">Not Onboarded</span>}
                                        </td>
                                        <td>
                                            <button className='btn' onClick={() => ViewSalesRep(salesreps.id)}>
                                                <i className="ri-eye-line"></i>
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                    <div className="row mt-3">
                        <nav aria-label="Page navigation">
                            {renderPagination()}
                        </nav>
                    </div>
                </div>
            </div>
        </>
    );
}
