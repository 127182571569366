import React from "react";
import Modal from 'react-modal';
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./components/utils/loggedin";

const root = ReactDOM.createRoot(document.getElementById("root"));

const observerErrorHandler = (e) => {
  if (e.message.includes('ResizeObserver loop limit exceeded')) {
    return;
  }
  // Log other errors normally
  console.error(e);
};

window.addEventListener('error', observerErrorHandler);


root.render(
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
