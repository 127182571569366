import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useAuth } from '../../../components/utils/loggedin';

const DigitalHealthCardsTable = () => {
    const { token } = useAuth();
    const [cardsData, setCardsData] = useState([]);
    const [searchCard, setSearchCard] = useState("");
    const [searchActivated, setSearchActivated] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (page = 1) => {
        setIsLoading(true);
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/healthcard/totaldigital/cards?page=${page}&limit=${limit}&card_number=${searchCard}&activated=${searchActivated}`,
                {
                    headers: {
                        Signature: process.env.REACT_APP_API_SIGNATURE,
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            console.log('API Response:', response.data); // Log the entire response data

            if (response.status === 200) {
                const { digital_cards, pagination } = response.data;
                setCardsData(digital_cards || []);
                setCurrentPage(page);
                setTotalPages(pagination?.totalPages || 1);
                setTotalItems(pagination?.totalItems || 0);
            } else {
                setCardsData([]); // Set empty array if no data found
                console.warn('Non-200 response:', response.status);
            }
        } catch (error) {
            console.error("Error Fetching Cards:", error.response ? error.response.data : error.message);
            setCardsData([]); // Set empty array if an error occurs

        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        handleSubmit();
    }, [token, limit, searchActivated, searchCard]);

    const renderPagination = () => {
        if (totalItems <= limit) return null;

        const pageNumbers = [];
        const maxPageButtons = 5;
        let startPage = Math.max(currentPage - Math.floor(maxPageButtons / 2), 1);
        let endPage = Math.min(startPage + maxPageButtons - 1, totalPages);

        if (endPage - startPage < maxPageButtons - 1) {
            startPage = Math.max(endPage - maxPageButtons + 1, 1);
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(i)}>
                        {i}
                    </button>
                </li>
            );
        }

        return (
            <ul className="pagination">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button
                        className="page-link"
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        <i className="tf-icon ri-arrow-left-s-line ri-20px" />
                    </button>
                </li>

                {startPage > 1 && (
                    <>
                        <li className="page-item">
                            <button className="page-link" onClick={() => handlePageChange(1)}>
                                1
                            </button>
                        </li>
                        {startPage > 2 && (
                            <li className="page-item disabled">
                                <span className="page-link">...</span>
                            </li>
                        )}
                    </>
                )}

                {pageNumbers}

                {endPage < totalPages && (
                    <>
                        {endPage < totalPages - 1 && (
                            <li className="page-item disabled">
                                <span className="page-link">...</span>
                            </li>
                        )}
                        <li className="page-item">
                            <button className="page-link" onClick={() => handlePageChange(totalPages)}>
                                {totalPages}
                            </button>
                        </li>
                    </>
                )}

                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button
                        className="page-link"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    >
                        <i className="tf-icon ri-arrow-right-s-line ri-20px" />
                    </button>
                </li>
            </ul>
        );
    };

    const handlePageChange = (page) => {
        if (page < 1 || page > totalPages) return;
        handleSubmit(page);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = String(date.getFullYear()).slice(-2);
        return `${day}/${month}/${year}`;
    };

    return (
        <div className="row">
            <div className="col-sm-12 col-md-6 mt-5 mt-md-0">
                <div className="dataTables_length" id="DataTables_Table_0_length">
                    <label>
                        Show{" "}
                        <select
                            name="DataTables_Table_0_length"
                            aria-controls="DataTables_Table_0"
                            className="form-select form-select-sm"
                            value={limit}
                            onChange={(e) => setLimit(parseInt(e.target.value, 10))}
                        >
                            <option value={7}>7</option>
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={75}>75</option>
                            <option value={100}>100</option>
                        </select>{" "}
                        entries
                    </label>
                </div>
            </div>

            <table className="table table-bordered table-striped">
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Card Number</th>
                        <th>Status</th>
                        <th>Created At</th>
                        <th>Updated At</th>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <input
                                type="text"
                                placeholder="Search by Card Number"
                                className="form-control"
                                value={searchCard}
                                onChange={(e) => setSearchCard(e.target.value)}
                            />
                        </td>
                        <td>
                            <select
                                className="form-control"
                                value={searchActivated}
                                onChange={(e) => setSearchActivated(e.target.value)}
                            >
                                <option value="">All Status</option>
                                <option value="true">Activated</option>
                                <option value="false">Not Activated</option>
                            </select>
                        </td>
                        <td></td>
                        <td></td>
                    </tr>
                </thead>
                <tbody>
                    {isLoading ? (
                        <tr>
                            <td colSpan="5" className="text-center">Loading...</td>
                        </tr>
                    ) : cardsData.length === 0 ? (
                        <tr>
                            <td colSpan="5" className="text-center">No data found</td>
                        </tr>
                    ) : (
                        cardsData.map((card, index) => (
                            <tr key={card.id || index}>
                                <td>{(currentPage - 1) * limit + index + 1}</td>
                                <td>{card.card_number}</td>
                                <td>{card.activated ? "Activated" : "Not Activated"}</td>
                                <td>{formatDate(card.created_at)}</td>
                                <td>{formatDate(card.updated_at)}</td>
                            </tr>
                        ))
                    )}
                </tbody>
            </table>

            {cardsData.length > 0 && (
                <div className="row mt-3">
                    <nav aria-label="Page navigation">
                        {renderPagination()}
                    </nav>
                </div>
            )}
        </div>
    );
};

export default DigitalHealthCardsTable;
