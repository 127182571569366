import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../components/utils/loggedin';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';

export default function EditLocation({ fetchLocations, item }) {
    const { token } = useAuth();

    // Initialize form data with fallback values in case item is null or undefined
    const [formData, setFormData] = useState({
        city: item?.city || '',
        address: item?.address || '',
        state: item?.state || '',
        zip: item?.zip || '',
        latitude: item?.latitude || '',
        longitude: item?.longitude || '',
        status: item?.status || 'active',  // Default status as 'active'
    });

    useEffect(() => {
        if (item) {
            setFormData({
                city: item.city || '',
                address: item.address || '',
                state: item.state || '',
                zip: item.zip || '',
                latitude: item.latitude || '',
                longitude: item.longitude || '',
                status: item.status || 'active',
            });
        }
    }, [item]);

    // Handle form input change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // Update location
    const updateLocation = async (e) => {
        e.preventDefault();
        try {
            await axios.put(
                `${process.env.REACT_APP_API_URL}/location/${item?.id}`,
                {
                    city: formData.city,
                    address: formData.address,
                    state: formData.state,
                    zip: formData.zip,
                    latitude: formData.latitude,
                    longitude: formData.longitude,
                    status: formData.status,
                },
                {
                    headers: {
                        "Signature": process.env.REACT_APP_API_SIGNATURE,
                        "Authorization": `Bearer ${token}`,
                    },
                }
            );
            fetchLocations(); // Refresh locations data after updating
            toast.success("Location updated successfully");
        } catch (error) {
            console.error('Error updating location:', error);
            toast.error("Failed to update location");
        }
    };

    return (
        <>
            <Helmet>
                <title>Manage Location - BharateClinics</title>
            </Helmet>
            <div
                className="offcanvas offcanvas-end editLocation"
                tabIndex={-1}
                id="editLocation"
                aria-labelledby="offcanvasEditLocationLabel"
            >
                <div className="offcanvas-header border-bottom">
                    <h5 id="offcanvasEditLocationLabel" className="offcanvas-title">
                        Edit Location
                    </h5>
                    <button
                        type="button"
                        className="btn-close text-reset"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                    />
                </div>
                <div className="offcanvas-body mx-0 flex-grow-0 h-100">
                    <form className="edit-location-form pt-0" onSubmit={updateLocation}>
                        <div className="form-floating form-floating-outline mb-3">
                            <input
                                type="text"
                                className="form-control"
                                id="location-city"
                                placeholder="City"
                                name="city"
                                value={formData.city}
                                onChange={handleInputChange}
                                required
                            />
                            <label htmlFor="location-city">City</label>
                        </div>

                        <div className="form-floating form-floating-outline mb-3">
                            <input
                                type="text"
                                className="form-control"
                                id="location-address"
                                placeholder="Address"
                                name="address"
                                value={formData.address}
                                onChange={handleInputChange}
                                required
                            />
                            <label htmlFor="location-address">Address</label>
                        </div>

                        <div className="form-floating form-floating-outline mb-3">
                            <input
                                type="text"
                                className="form-control"
                                id="location-state"
                                placeholder="State"
                                name="state"
                                value={formData.state}
                                onChange={handleInputChange}
                                required
                            />
                            <label htmlFor="location-state">State</label>
                        </div>

                        <div className="form-floating form-floating-outline mb-3">
                            <input
                                type="text"
                                className="form-control"
                                id="location-zip"
                                placeholder="ZIP Code"
                                name="zip"
                                value={formData.zip}
                                onChange={handleInputChange}
                                required
                            />
                            <label htmlFor="location-zip">ZIP Code</label>
                        </div>

                        <div className="form-floating form-floating-outline mb-3">
                            <input
                                type="number"
                                className="form-control"
                                id="location-latitude"
                                placeholder="Latitude"
                                name="latitude"
                                value={formData.latitude}
                                onChange={handleInputChange}
                                required
                            />
                            <label htmlFor="location-latitude">Latitude</label>
                        </div>

                        <div className="form-floating form-floating-outline mb-3">
                            <input
                                type="number"
                                className="form-control"
                                id="location-longitude"
                                placeholder="Longitude"
                                name="longitude"
                                value={formData.longitude}
                                onChange={handleInputChange}
                                required
                            />
                            <label htmlFor="location-longitude">Longitude</label>
                        </div>

                        <div className="form-floating form-floating-outline mb-3">
                            <select
                                className="form-select"
                                id="location-status"
                                name="status"
                                value={formData.status}
                                onChange={handleInputChange}
                            >
                                <option value="active">Active</option>
                                <option value="inactive">Inactive</option>
                            </select>
                            <label htmlFor="location-status">Status</label>
                        </div>

                        <hr />
                        <button type="submit" className="btn btn-success me-sm-3 me-1 mt-4">
                            Save
                        </button>
                        <button
                            type="reset"
                            className="btn btn-outline-secondary mt-4"
                            data-bs-dismiss="offcanvas"
                        >
                            Cancel
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
}
