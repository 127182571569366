import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../components/utils/loggedin';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles for the Quill editor
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';


export default function EditEmail({ fetchEmailTemplate, item }) {
    const { token } = useAuth();

    // Initialize form data with fallback values in case item is null or undefined
    const [formData, setFormData] = useState({
        subject: item?.subject || '',
        content: item?.content || ''
    });

    useEffect(() => {
        if (item) {
            setFormData({
                subject: item.subject || '',
                content: item.content || ''
            });
        }
    }, [item]);

    // Handle form input change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // Handle content change for ReactQuill
    const handleContentChange = (value) => {
        setFormData((prevData) => ({
            ...prevData,
            content: value, // Update content field in formData
        }));
    };

    // Update email template
    const updateEmail = async (e) => {
        e.preventDefault();
        try {
            await axios.put(
                `${process.env.REACT_APP_API_URL}/email-template/${item?.id}`,
                {
                    subject: formData.subject,
                    content: formData.content
                },
                {
                    headers: {
                        "Signature": process.env.REACT_APP_API_SIGNATURE,
                        "Authorization": `Bearer ${token}`
                    }
                }
            );
            fetchEmailTemplate(); // Refresh the email template data after updating
            toast.success("Email Template updated successfully")
        } catch (error) {
            console.error('Error updating email template:', error);
        }
    };

    return (
        <>
            <Helmet>
                <title>Manage Email Template - BharateClinics </title>
            </Helmet>
            <div
                className="offcanvas offcanvas-end editTemplate"
                tabIndex={-1}
                id="editTemplate"
                aria-labelledby="offcanvasAddTestimonialLabel"
            >
                <div className="offcanvas-header border-bottom">
                    <h5 id="offcanvasAddTestimonialLabel" className="offcanvas-title">
                        Edit Template

                    </h5>
                    <button
                        type="button"
                        id="closeModal"
                        className="btn-close text-reset"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                    />
                </div>
                <div className="offcanvas-body mx-0 flex-grow-0 h-100">
                    <form className="edit-email-template pt-0" onSubmit={updateEmail}>

                        <div className="form-floating form-floating-outline mb-3">
                            <input
                                type="text"
                                className="form-control"
                                id="email-template-subject"
                                placeholder="Email Subject"
                                name="subject"
                                value={formData.subject}
                                onChange={handleInputChange}
                                aria-label="Subject"
                            />
                            <label htmlFor="email-template-subject">Subject</label>
                        </div>

                        {/* Replace textarea with ReactQuill for rich text editing */}
                        <div className="form-floating form-floating-outline mb-3">
                            <ReactQuill
                                theme="snow"
                                value={formData.content}
                                onChange={handleContentChange}
                                placeholder="Email Content"
                                style={{ height: "300px" }} // Set desired height for the editor
                            />
                            <label htmlFor="email-template-content">Content</label>
                        </div>

                        <hr />
                        <button type="submit" className="btn btn-success me-sm-3 me-1 mt-4">
                            Save
                        </button>
                        <button
                            type="reset"
                            className="btn btn-outline-secondary mt-4"
                            data-bs-dismiss="offcanvas"
                        >
                            Cancel
                        </button>
                    </form>

                    {/* Add message below the form */}
                </div>
            </div>


        </>
    );
}
