export const permissions = [
    {
        ADMIN_PANEL: {
            profile: {
                reset_password: false,
                update_account: false,
                view_account: false,
                update_profile_img: false,
            },
            teams: {
                manage_teams: false,
                teams_edit: false,
                teams_suspend: false,
                teams_view: false,
                teams_add_new: false,
                teams_security: false,
                teams_cards: false,
                teams_verify: false,
                teams_suspend: false,
            },
            patient: {

                manage_patient: false,
                patient_create: false,
                patient_update: false,
                patient_view: false,
            },
            doctor: {
                manage_doctor: false,
                Doctor_Admin_view: false,
                Doctor_Admin_edit: false,
                Doctor_Admin_update: false,
                Doctor_Admin_password: false,
                Doctor_Admin_verify: false,
                doctorAdmin_update: false,
                doctorAdmin_view: false,
                doctorAdmin_password: false,
                add_new_doctor: false,
                view_doctor: false,
                edit_doctors: false,
                verify_doctor: false,
                onboard_doctor: false,
                doctor_password: false,
                suspend_doctor: false,
            },
            sales: {
                manage_sales: false,
                manage_salesreps: false,
                Sales_Admin_view: false,
                Sales_Admin_edit: false,
                Sales_Admin_update: false,
                Sales_Admin_password: false,
                Sales_Admin_verify: false,
                sales_update: false,
                sales_view: false,
                sales_password: false,
                add_new_salesrep: false,
                view_salesrep: false,
                allotcard_salesrep: false,
                generate_card_salesrep: false,
                edit_salesreps: false,
                verify_salesrep: false,
                onboard_salesrep: false,
                suspend_salesreps: false,
            },
            clinic: {

                manage_clinic: false,


                Clinic_Admin_view: false,
                Clinic_Admin_edit: false,
                Clinic_Admin_update: false,
                Clinic_Admin_password: false,
                Clinic_Admin_verify: false,
            },
            testimonial: {
                manage_testimonial: false,
                testimonial_create: false,
                testimonial_update: false,
                testimonial_delete: false,
            },
            contact: {
                manage_contact: false,
                delete_contact: false,
            },
            location: {
                manage_location: false,
                create_location: false,
            },
            email_template: {
                manage_email_template: false,
                update_email_template: false,
            },
            sms_template: {
                manage_sms_template: false,
                update_sms_template: false,
            },
        },
        // DOCTOR_PANEL: {
        //     profile: {
        //         reset_password: false,
        //         update_account: false,
        //         view_account: false,
        //         update_profile_img: false,
        //         doctor_eduction: false,
        //         doctor_experience: false,
        //         doctor_specialization: false,
        //         doctor_location: false
        //     },
        //     appointment: {
        //         approve: false,
        //         reject: false,
        //         completed: false,
        //     },
        //     doctor: {
        //         manage_doctors: false,
        //         doctor_view: false,
        //         doctor_appointments: false,
        //         doctor_patients: false,
        //         doctor_transaction: false,
        //     }

        // },
        // SALESREP_PANEL: {
        //     profile: {
        //         reset_password: false,
        //         update_account: false,
        //         view_account: false,
        //         update_profile_img: false,
        //         salesreps_profile: false,
        //     },
        //     salesrep: {

        //         manage_card: false,
        //         manage_patient: false,
        //         activate_card: false,
        //         view_activate_card: false,
        //     }
        // },
        // CLINIC_PANEL: {
        //     clinic: {

        //         manage_clinics: false,
        //     }
        // },
        // USER_PANEL: {
        //     user: {

        //         user_create: false,
        //         user_update: false,
        //         user_view: false,
        //     }
        // },
        // faq: {
        //     faq_create: false,
        //     faq_update: false,
        //     faq_view: false,
        //     faq_delete: false,
        // }
    },
];