import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import axios from 'axios';
import { useAuth } from '../../components/utils/loggedin';

const CardsChartData = () => {
    const { token } = useAuth();
    const [healthCards, setHealthCards] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        axios
            .get(`${process.env.REACT_APP_API_URL}/admin/superadmin/statistics`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    Signature: process.env.REACT_APP_API_SIGNATURE,
                },
            })
            .then((response) => {
                const cards = response.data.statistics.healthCards;
                setHealthCards(cards);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching health cards:', error);
                setLoading(false);
            });
    }, [token]);

    // Calculate percentages or set to 0 if no data is available
    const digitalPercentage = healthCards.digitalPercentage ? parseFloat(healthCards.digitalPercentage) : 0;
    const physicalPercentage = healthCards.physicalPercentage ? parseFloat(healthCards.physicalPercentage) : 0;

    // Data for the charts
    const chartsData = [
        {
            color: '#72e128', // Green color for digital cards
            series: digitalPercentage,
            label: 'Digital Cards',
            count: healthCards.digital,
        },
        {
            color: '#ff4d49', // Red color for physical cards
            series: physicalPercentage,
            label: 'Physical Cards',
            count: healthCards.physical,
        },
    ];

    // Chart options configuration
    const getOptions = (color) => ({
        chart: {
            height: 70,
            type: 'radialBar',
            sparkline: {
                enabled: true,
            },
        },
        plotOptions: {
            radialBar: {
                hollow: {
                    size: '50%', // Size of the hollow area
                },
                dataLabels: {
                    name: {
                        show: false,
                    },
                    value: {
                        offsetY: 5, // Vertical alignment of the percentage
                        fontSize: '15px',
                        fontWeight: '600',
                        color: color,
                        show: true,
                        formatter: function (val) {
                            return Math.round(val) + '%'; // Ensure percentage formatting
                        },
                    },
                },
                track: {
                    background: '#E0E0E0',
                    strokeWidth: '100%',
                },
                strokeWidth: '14',
            },
        },
        fill: {
            colors: [color],
        },
        stroke: {
            lineCap: 'round',
        },
        labels: ['Progress'],
    });

    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <div className="col-12 col-xxl-4 col-md-6 mt-3">
            <div className="card h-100">
                <div className="card-header d-flex align-items-center justify-content-between">
                    <h5 className="card-title m-0 me-2">Cards Overview</h5>
                </div>
                <div className="card-body">
                    <ul className="p-0 m-0">
                        {chartsData.map((item, index) => (
                            <li className="d-flex mb-4" key={index}>
                                <div className="me-3">
                                    <Chart
                                        options={getOptions(item.color)}
                                        series={[item.series]}
                                        type="radialBar"
                                        height={80}
                                        width={80}
                                    />
                                </div>
                                <div className="row w-100 align-items-center">
                                    <div className="col-9">
                                        <div className="me-2">
                                            <h6 className="mb-2">{item.label}</h6>
                                            <p className="mb-0 small">{item.count} Cards</p>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default CardsChartData;
