import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useAuth } from '../../../components/utils/loggedin';

export default function ViewSalesRepDetails({ salesRepData }) {
    const [otp, setOtp] = useState("");
    const { token, admin } = useAuth()
    const [formdata, setFormdata] = useState({
        first_name: salesRepData.first_name || '',
        last_name: salesRepData.last_name || '',
        email: salesRepData.email || '',
        phone: salesRepData.phone || '',
        address: salesRepData.address || '',
        state: salesRepData.state || '',
        city: salesRepData.city || '',
        zip: salesRepData.zip || '',
        profile_img: null, // For the file input
    });
    const [resendTimer, setResendTimer] = useState(0); // Timer for resend
    const [resendDisabled, setResendDisabled] = useState(false); // Flag for resend button

    useEffect(() => {
        setFormdata(
            {
                first_name: salesRepData.first_name || '',
                last_name: salesRepData.last_name || '',
                email: salesRepData.email || '',
                phone: salesRepData.phone || '',
                address: salesRepData.address || '',
                state: salesRepData.state || '',
                city: salesRepData.city || '',
                zip: salesRepData.zip || '',
                profile_img: null, // For the file input
            }
        )
    }, [salesRepData])

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = String(date.getFullYear()).slice(-2); // Last two digits of the year
        return `${day}/${month}/${year}`;
    };


    const updateSalesRep = async () => {
        const formData = new FormData();

        // Append all the form fields to FormData
        Object.keys(formdata).forEach(key => {
            if (key !== 'profile_img') { // Skip file
                formData.append(key, formdata[key]);
            }
        });

        // Append the file if it exists
        if (formdata.profile_img) {
            formData.append('profile_img', formdata.profile_img);
        }

        try {
            const response = await axios.put(
                `${process.env.REACT_APP_API_URL}/salesrep/updatesalesrepbyadmin/${salesRepData.id}`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`, // Authorization token
                        Signature: process.env.REACT_APP_API_SIGNATURE, // Signature for additional security
                        'Content-Type': 'multipart/form-data', // Necessary for file uploads
                    },
                }
            );

            if (response.status === 200) {
                toast.success(response.data.message); // Success message
                document.getElementById('closeSalesModel').click(); // Close the modal
                window.location.reload()
            } else {
                toast.error(response.data.message); // Error message
            }
        } catch (error) {
            console.error("Error during updating sales representative:", error);
            toast.error("An error occurred during updating the sales representative."); // Generic error message
        }
    };



    const handleChangeOtp = (e, index) => {
        const value = e.target.value;
        if (isNaN(value)) return; // Prevent non-numeric input

        const otpArray = otp.split(""); // Ensure otp is a string
        otpArray[index] = value;

        // Combine the array back into a string
        setOtp(otpArray.join(""));

        // Focus on next input
        if (e.target.nextSibling) {
            e.target.nextSibling.focus();
        }
    };

    const verifySalesRep = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/salesrep/verifysalesrep/${salesRepData.id}`, // Pass salesRepId directly in the URL
                { phone: salesRepData.phone, otp },
                {
                    headers: {
                        Signature: process.env.REACT_APP_API_SIGNATURE,
                        Authorization: `Bearer ${token}`, // Token for authorization
                    },
                }
            );

            // If the verification is successful, log the success and display a success toast
            console.log("Verification successful:", response.data);
            toast.success(response.data.message || "Sales Representative verified successfully!");

            // Refresh the page to reflect the verified status
            window.location.reload();

            // Fetch updated data
            // fetchSalesRepData();

        } catch (error) {
            console.error("Error verifying sales rep:", error);

            // Display an error toast with a fallback message
            toast.error(
                error.response?.data?.message || "Failed to verify sales representative."
            );
        }
    };

    // unverify sales 

    const unverifySalesRep = async (e) => {
        e.preventDefault(); // Prevent form submission

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/salesrep/unverifysales/${salesRepData.id}`, // Pass salesRepId directly in the URL
                {}, // Empty body since this is a PUT request with no body content
                {

                    headers: {
                        Signature: process.env.REACT_APP_API_SIGNATURE,
                        Authorization: `Bearer ${token}`, // Token for authorization
                    },
                }
            );

            // If the unverification is successful, log the success and display a success toast
            console.log("Unverification successful:", response.data);
            toast.success(response.data.message || "Sales Representative unverified successfully!");

            // Fetch updated data
            // fetchSalesRepData();
            window.location.reload()
        } catch (error) {
            console.error("Error unverifying sales rep:", error);

            // Display an error toast with a fallback message
            toast.error(
                error.response?.data?.message || "Failed to unverify sales representative."
            );
        }
    };

    const initiateVerification = async () => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/salesrep/initiatesalesrepverification`,
                {
                    phone: salesRepData.phone,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Signature: process.env.REACT_APP_API_SIGNATURE,
                    },
                }
            );
            if (response.status === 200) {
                toast.success(response.data.message);
                // setResendTimer(10); // Set timer for 30 seconds
                // setResendDisabled(true); // Disable resend button
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const resendOtp = async () => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/salesrep/initiatesalesrepverification`,
                { phone: salesRepData.phone },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Signature: process.env.REACT_APP_API_SIGNATURE,
                    },
                }
            );
            if (response.status === 200) {
                toast.success("OTP has been resent. Please check your phone.");
                setResendTimer(10); // Reset timer for 30 seconds
                setResendDisabled(true); // Disable resend button
            } else {
                toast.error("Failed to resend OTP.");
            }
        } catch (error) {
            console.error(error);
            toast.error("An error occurred while resending OTP.");
        }
    };

    useEffect(() => {
        if (resendTimer > 0) {
            const timer = setInterval(() => {
                setResendTimer((prev) => prev - 1);
            }, 1000);
            return () => clearInterval(timer); // Cleanup timer on unmount
        } else {
            setResendDisabled(false); // Re-enable resend button when timer reaches 0
        }
    }, [resendTimer]);

    const handleFileChange = (e) => {
        setFormdata({
            ...formdata,
            profile_img: e.target.files[0], // Assuming profile_img is the key for the file
        });
    };

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        if (type === 'file') {
            // Handle file input
            setFormdata({
                ...formdata,
                [name]: files[0] || null, // Store the selected file
            });
        } else {
            // Handle text input
            setFormdata({
                ...formdata,
                [name]: value, // Update text input values
            });
        }
    };

    return (
        <>


            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <h5 className="mb-0">Details</h5>
                    <div className="d-flex">
                        {
                            admin?.role?.permissions?.ADMIN_PANEL?.sales?.edit_salesreps &&
                            <button button
                                className="btn  btn-label-primary me-2"
                                data-bs-target="#offcanvasAddUser"
                                data-bs-toggle="offcanvas"
                            >
                                <i class="ri-pencil-line me-2"></i>
                                Edit
                            </button>
                        }
                        {salesRepData.verified ? <>

                            {admin?.role?.permissions?.ADMIN_PANEL?.sales?.suspend_salesreps &&
                                <button
                                    data-bs-toggle="modal"
                                    data-bs-target="#suspendAdmin"
                                    className="btn btn-outline-danger suspend-user"
                                >
                                    Suspend
                                </button>
                            }
                        </>
                            : <>
                                {
                                    admin?.role?.permissions?.ADMIN_PANEL?.sales?.verify_salesrep &&
                                    <button
                                        data-bs-toggle="modal"
                                        data-bs-target="#verifyOTP"
                                        className="btn btn-outline-success suspend-user"
                                        onClick={() => initiateVerification()}
                                    >
                                        Verify
                                    </button>
                                }
                            </>
                        }
                    </div>
                </div>
                <div className="table-responsive text-nowrap">
                    <table className="table">
                        <tbody className="table-border-bottom-0">
                            <tr>
                                <td>
                                    <span className="fw-medium">Username</span>
                                </td>
                                <td>{salesRepData.username || "BHSRXXXX"}</td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="fw-medium">Name</span>
                                </td>
                                <td>{salesRepData.first_name + ' ' + salesRepData.last_name}</td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="fw-medium">Email</span>
                                </td>
                                <td>{salesRepData.email}</td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="fw-medium">Phone</span>
                                </td>
                                <td>{salesRepData.phone}</td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="fw-medium">Address</span>
                                </td>
                                <td>
                                    {salesRepData.address +
                                        " " +
                                        salesRepData.state +
                                        " " +
                                        salesRepData.city +
                                        " " +
                                        salesRepData.zip}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="fw-medium">Onboarded</span>
                                </td>
                                <td>
                                    {salesRepData.onboarded ? "Yes" : "No"}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="fw-medium">Joined On</span>
                                </td>
                                <td>
                                    {formatDate(salesRepData.created_at)}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                {/* verify model  */}
                <div
                    className="modal fade"
                    id="verifyOTP"
                    tabIndex={-1}
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-simple modal-enable-otp modal-dialog-centered">
                        <div className="modal-content">
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                            <div className="card-body mt-1">
                                <h4 className="mb-1"> Verification of the user 💬</h4>
                                <p className="text-start mb-5">
                                    We sent a verification code to your mobile. Enter the code from
                                    the mobile in the field below.
                                    <span className="d-block mt-1 h6">{salesRepData.phone}</span>
                                </p>
                                <p className="mb-0">Type your 6 digit security code</p>
                                <form id="twoStepsForm" onSubmit={verifySalesRep}>

                                    <div className="mb-3">
                                        <div className="auth-input-wrapper d-flex align-items-center justify-content-between numeral-mask-wrapper">
                                            {[...Array(6)].map((_, index) => (
                                                <input
                                                    key={index}
                                                    type="tel"
                                                    className="form-control auth-input text-center numeral-mask h-px-50 mx-sm-1 my-2"
                                                    maxLength={1}
                                                    value={otp[index] || ""}
                                                    onChange={(e) => handleChangeOtp(e, index)}
                                                    onFocus={(e) => e.target.select()}
                                                    autoFocus={index === 0}
                                                />
                                            ))}
                                        </div>
                                        {/* Hidden field not necessary when using string */}
                                    </div>
                                    <button
                                        data-bs-dismiss="modal"
                                        className="btn btn-primary d-grid w-100 mb-5"
                                        type="submit"
                                    >
                                        Verify my account
                                    </button>
                                    <div className="text-center">
                                        Didn't get the code?
                                        {resendTimer > 0 ? (
                                            <span style={{ color: "blue", padding: "2px" }}>Resend in {resendTimer}s</span>
                                        ) : (
                                            <a href="javascript:void(0);" onClick={resendOtp}>Resend</a>
                                        )}
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Suspend Admin Modal */}
                <div
                    className="modal fade"
                    id="suspendAdmin"
                    tabIndex={-1}
                    aria-hidden="true"
                    data-bs-dismiss="modal"
                >
                    <div className="modal-dialog modal-simple modal-enable-otp modal-dialog-centered">
                        <div className="modal-content">
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                            <div className="modal-body p-0">
                                <div className="text-center mb-6">
                                    <h2 className="mb-2">Are you sure !</h2>
                                    {/* <p>Verify Your Mobile Number for SMS</p> */}
                                </div>
                                <p className="mb-5 text-center">
                                    Are you sure you want to unverify this admin account?
                                    <br /> Please confirm your decision to proceed with the
                                    unverification process.
                                </p>

                                <form
                                    id="enableOTPForm"
                                    className="row g-5"
                                    onsubmit="return false"
                                >

                                    <div className="col-12 d-flex flex-wrap justify-content-center gap-4 row-gap-4">
                                        <button
                                            type="submit"
                                            className="btn btn-success"
                                            onClick={(e) => unverifySalesRep(e)}
                                            data-bs-dismiss="modal"
                                        >
                                            Unverify
                                        </button>
                                        <button
                                            type="reset"
                                            className="btn btn-outline-danger"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {/* edit sales rep model start's here  */}
                <div
                    className="offcanvas offcanvas-end addadmin"
                    tabIndex={-1}
                    id="offcanvasAddUser"
                    aria-labelledby="offcanvasAddUserLabel"
                // Adjust this value as needed
                >
                    <div className="offcanvas-header border-bottom">
                        <h5 id="offcanvasAddUserLabel" className="offcanvas-title">
                            Edit User
                        </h5>
                        <button
                            type="button"
                            className="btn-close text-reset"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                        />
                    </div>
                    <div className="offcanvas-body mx-0 flex-grow-0 h-100">
                        <form
                            className="add-new-user pt-0"
                            id="editUser"
                            onSubmit={(e) => {
                                e.preventDefault();
                                updateSalesRep(); // Pass the formdata to the update function
                            }}
                        >
                            <div className="form-floating form-floating-outline mb-5">
                                <input
                                    type="file"
                                    id="profile_img"
                                    className="form-control"
                                    name="profile_img"

                                    onChange={handleFileChange} // Note: This should be updated to handle file changes
                                />
                                <label htmlFor="profile_img">Profile Image</label>
                            </div>
                            <div className="form-floating form-floating-outline mb-5">
                                <input
                                    type="text"
                                    id="add-user-firstname"
                                    className="form-control"
                                    name="first_name"
                                    value={formdata.first_name}
                                    onChange={handleChange}
                                />
                                <label htmlFor="add-user-firstname">First Name</label>
                            </div>
                            <div className="form-floating form-floating-outline mb-5">
                                <input
                                    type="text"
                                    id="add-user-lastname"
                                    className="form-control"
                                    name="last_name"
                                    value={formdata.last_name}
                                    onChange={handleChange}
                                />
                                <label htmlFor="add-user-lastname">Last Name</label>
                            </div>
                            <div className="form-floating form-floating-outline mb-5">
                                <input
                                    type="text"
                                    id="add-user-email"
                                    className="form-control"
                                    name="email"
                                    value={formdata.email}
                                    onChange={handleChange}
                                />
                                <label htmlFor="add-user-email">Email</label>
                            </div>

                            <div className="form-floating form-floating-outline mb-5">
                                <input
                                    type="text"
                                    id="add-user-contact"
                                    className="form-control"
                                    name="phone"
                                    value={formdata.phone}
                                    onChange={handleChange}
                                />
                                <label htmlFor="add-user-contact">Contact</label>
                            </div>

                            <div className="form-floating form-floating-outline mb-5">
                                <textarea
                                    id="TagifyLanguageSuggestion"
                                    name="address"
                                    className="form-control h-auto"
                                    value={formdata.address}
                                    onChange={handleChange}
                                />
                                <label htmlFor="TagifyLanguageSuggestion">Address</label>
                            </div>

                            <div className="form-floating form-floating-outline mb-5">
                                <input
                                    id="TagifyLanguageSuggestionState"
                                    name="state"
                                    className="form-control h-auto"
                                    value={formdata.state}
                                    onChange={handleChange}
                                />
                                <label htmlFor="TagifyLanguageSuggestionState">State</label>
                            </div>

                            <div className="form-floating form-floating-outline mb-5">
                                <input
                                    id="TagifyLanguageSuggestionCity"
                                    name="city"
                                    className="form-control h-auto"
                                    value={formdata.city}
                                    onChange={handleChange}
                                />
                                <label htmlFor="TagifyLanguageSuggestionCity">City</label>
                            </div>

                            <div className="form-floating form-floating-outline mb-5">
                                <input
                                    id="TagifyLanguageSuggestionZip"
                                    name="zip"
                                    className="form-control h-auto"
                                    value={formdata.zip}
                                    onChange={handleChange}
                                />
                                <label htmlFor="TagifyLanguageSuggestionZip">Zip</label>
                            </div>

                            <div className="col-12 text-center d-flex flex-wrap justify-content-center gap-4 row-gap-4">
                                <button
                                    type="submit"
                                    className="btn btn-success me-sm-3 me-1 data-submit"
                                >
                                    Update
                                </button>
                                <button
                                    type="reset"
                                    className="btn btn-outline-secondary"
                                    data-bs-dismiss="offcanvas"
                                    id='closeSalesModel'
                                >
                                    Cancel
                                </button>
                            </div>
                        </form>

                    </div>
                </div>
            </div >


        </>
    )
}
