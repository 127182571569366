import React from 'react';

const DataDisplayModal = ({ item }) => {
    return (
        <div className="modal fade" id="openModal" tabIndex={-1} aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                    {/* Close Button */}
                    <div className='modal-header'>

                        {/* Close Button */}
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>

                    {/* Modal Body */}
                    <div className="modal-body p-5">
                        {/* Modal Header */}
                        <div className="mb-4">
                            <h5 className="modal-title text-center">Sms Template Details</h5>
                        </div>

                        {/* Data Display Section */}
                        <div className="mb-4">
                            {/* Name Field */}
                            <div className="mb-3">
                                <label htmlFor="templateName" className="form-label fw-bold">
                                    Name:
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="templateName"
                                    value={item?.name}
                                    readOnly
                                />
                            </div>



                            {/* Content Field */}
                            <div className="mb-3">
                                <label htmlFor="templateContent" className="form-label fw-bold">
                                    Content:
                                </label>
                                <textarea
                                    className="form-control"
                                    id="templateContent"
                                    rows="10"
                                    value={item?.content}
                                    readOnly
                                ></textarea>
                            </div>
                        </div>

                        {/* Footer Actions */}

                    </div>
                </div>
            </div>
        </div>
    );
};

export default DataDisplayModal;
