import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "../../../components/utils/loggedin";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function Account({ user, userDetails }) {
  const [formdata, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    gender: "",
    dob: "",
    address: "",
    state: "",
    city: "",
    zip: "",
    user_type: "",
  });

  const [otp, setOtp] = useState(""); // Initialize as a string
  const [errorMessage, setErrorMessage] = useState("");
  const { token, admin } = useAuth();
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formdata, [e.target.name]: e.target.value });
  };

  const updateUserData = async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/user/updateuser/${user.id}`,
        formdata,
        {
          headers: {
            Signature: process.env.REACT_APP_API_SIGNATURE,
          },
        }
      );

      console.log("Update successful:", response.data);
      setFormData(response.data.user); // Update the form data with the updated user details
      toast.success(response.data.message); // Show success message
      window.location.reload()
      // Navigate to the /admins/patient page
      // window.location.reload(); // Force reload if needed
    } catch (error) {
      console.error("Error updating user data:", error);
      toast.error(error?.response?.data?.message || "Failed to update user data. Please try again."); // Show error message
    }
  };

  useEffect(() => {
    // Merge data from user and userDetails to ensure both are loaded
    if (user && userDetails) {
      setFormData((prevData) => ({
        ...prevData,
        first_name: user.first_name || "",
        last_name: user.last_name || "",
        email: user.email || "",
        phone: user.phone || "",
        gender: userDetails.gender || "",
        dob: userDetails.dob || "",
        address: userDetails.address || "",
        state: userDetails.state || "",
        city: userDetails.city || "",
        zip: userDetails.zip || "",
        user_type: user.user_type || "",
      }));
    }
  }, [user, userDetails]);

  return (
    <>
      {/* Project table */}
      <div className="card">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h5 className="mb-0">Details</h5>
          <div className="d-flex">
            {admin?.role?.permissions?.ADMIN_PANEL?.patient?.patient_update && <button
              className="btn btn-primary me-2"
              data-bs-target="#editUser"
              data-bs-toggle="offcanvas"
            >
              <i className="ri-pencil-line"></i>
              Edit
            </button>}
          </div>
        </div>
        <div className="table-responsive text-nowrap">
          <table className="table">
            <tbody className="table-border-bottom-0">
              <tr>
                <td>
                  <span className="fw-medium">Email</span>
                </td>
                <td>{user.email}</td>
              </tr>
              <tr>
                <td>
                  <span className="fw-medium">Patient Name</span>
                </td>
                <td>{user.first_name + " " + user.last_name}</td>
              </tr>
              <tr>
                <td>
                  <span className="fw-medium">Phone</span>
                </td>
                <td>{user.phone}</td>
              </tr>
              <tr>
                <td>
                  <span className="fw-medium">Address</span>
                </td>
                <td>{userDetails.address}</td>
              </tr>
              <tr>
                <td>
                  <span className="fw-medium">City</span>
                </td>
                <td>{userDetails.city}</td>
              </tr>
              <tr>
                <td>
                  <span className="fw-medium">Zip</span>
                </td>
                <td>{userDetails.zip}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* Edit User Modal */}
      <div
        className="offcanvas offcanvas-end"
        id="editUser"
        tabIndex={-1}
        aria-labelledby="editUserLabel"
        style={{ width: "600px" }}
      >
        <div className="offcanvas-header border-bottom">
          <h5 className="offcanvas-title" id="editUserLabel">
            Edit User Information
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body flex-grow-1">
          <form
            id="editUserForm"
            className="row g-3"
            onSubmit={(e) => {
              e.preventDefault();
              updateUserData();
            }}
          >
            {/* First Name */}
            <div className="col-12">
              <div className="input-group input-group-merge">
                <div className="form-floating form-floating-outline">
                  <input
                    type="text"
                    id="modalEditUserFirst"
                    name="first_name"
                    className="form-control"
                    value={formdata.first_name}
                    onChange={handleChange}
                    placeholder="John"
                    aria-label="John"
                  />
                  <label htmlFor="modalEditUserFirst">First Name</label>
                </div>
              </div>
            </div>

            {/* Last Name */}
            <div className="col-12">
              <div className="input-group input-group-merge">
                <div className="form-floating form-floating-outline">
                  <input
                    type="text"
                    id="modalEditUserLast"
                    name="last_name"
                    className="form-control"
                    value={formdata.last_name}
                    onChange={handleChange}
                    placeholder="Doe"
                    aria-label="Doe"
                  />
                  <label htmlFor="modalEditUserLast">Last Name</label>
                </div>
              </div>
            </div>

            {/* Email */}
            <div className="col-12">
              <div className="input-group input-group-merge">
                <div className="form-floating form-floating-outline">
                  <input
                    type="email"
                    id="modalEditUserEmail"
                    name="email"
                    className="form-control"
                    value={formdata.email}
                    onChange={handleChange}
                    placeholder="john.doe@example.com"
                    aria-label="john.doe@example.com"
                  />
                  <label htmlFor="modalEditUserEmail">Email</label>
                </div>
              </div>
            </div>

            {/* Phone */}
            <div className="col-12">
              <div className="input-group input-group-merge">
                <div className="form-floating form-floating-outline">
                  <input
                    type="tel"
                    id="modalEditUserPhone"
                    name="phone"
                    className="form-control"
                    value={formdata.phone}
                    onChange={handleChange}
                    placeholder="+91"
                    aria-label="Contact"
                  />
                  <label htmlFor="modalEditUserPhone">Phone</label>
                </div>
              </div>
            </div>

            {/* Address */}
            <div className="col-12">
              <div className="input-group input-group-merge">
                <div className="form-floating form-floating-outline">
                  <input
                    type="text"
                    id="modalEditUserAddress"
                    name="address"
                    className="form-control"
                    value={formdata.address}
                    onChange={handleChange}
                    placeholder="Address"
                    aria-label="Address"
                  />
                  <label htmlFor="modalEditUserAddress">Address</label>
                </div>
              </div>
            </div>

            {/* City */}
            <div className="col-12">
              <div className="input-group input-group-merge">
                <div className="form-floating form-floating-outline">
                  <input
                    type="text"
                    id="modalEditUserCity"
                    name="city"
                    className="form-control"
                    value={formdata.city}
                    onChange={handleChange}
                    placeholder="City"
                    aria-label="City"
                  />
                  <label htmlFor="modalEditUserCity">City</label>
                </div>
              </div>
            </div>

            {/* State */}
            <div className="col-12">
              <div className="input-group input-group-merge">
                <div className="form-floating form-floating-outline">
                  <input
                    type="text"
                    id="modalEditUserState"
                    name="state"
                    className="form-control"
                    value={formdata.state}
                    onChange={handleChange}
                    placeholder="State"
                    aria-label="State"
                  />
                  <label htmlFor="modalEditUserState">State</label>
                </div>
              </div>
            </div>

            {/* Zip */}
            <div className="col-12">
              <div className="input-group input-group-merge">
                <div className="form-floating form-floating-outline">
                  <input
                    type="text"
                    id="modalEditUserZip"
                    name="zip"
                    className="form-control"
                    value={formdata.zip}
                    onChange={handleChange}
                    placeholder="Zip"
                    aria-label="Zip"
                  />
                  <label htmlFor="modalEditUserZip">Zip</label>
                </div>
              </div>
            </div>

            {/* Submit and Cancel Buttons */}
            <div className="col-12">
              <button
                type="submit"
                className="btn btn-primary me-sm-4 me-1"
              >
                Submit
              </button>
              <button
                type="reset"
                className="btn btn-outline-secondary"
                data-bs-dismiss="offcanvas"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
