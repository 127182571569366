import { Link } from 'react-router-dom'
import AllDoctors from './alldoctors'
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../../components/utils/loggedin';
import { Helmet } from 'react-helmet';

export default function Doctors() {


    return (
        <>
            <Helmet>
                <title>Doctors : BharateClinics</title>
            </Helmet>
            {/* Users List Table */}
            <div className="card">
                <div className="card-header border-bottom">
                    <h5 className="card-title mb-0">Doctors</h5>

                </div>
                <div className="card-datatable table-responsive">
                    <div
                        id="DataTables_Table_0_wrapper"
                        className="dataTables_wrapper dt-bootstrap5 no-footer"
                    >

                        <AllDoctors />
                    </div>
                </div>
            </div>
        </>

    )
}
