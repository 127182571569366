import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useAuth } from "../../components/utils/loggedin";

export default function VerifyAdminOTP() {
  const { token } = useAuth();
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const phone = location.state?.phone; // Use optional chaining to access phone safely
  const [resendTimer, setResendTimer] = useState(0); // Timer for resend
  const [resendDisabled, setResendDisabled] = useState(false); // Flag for resend button

  // Moved useEffect to always run
  useEffect(() => {
    if (!phone) {
      navigate("/"); // Redirect to home if no phone number is found in state
    }
  }, [navigate, phone]); // Ensure `navigate` and `phone` are dependencies

  const handleChange = (e, index) => {
    const value = e.target.value;

    // Check for digit input
    if (/^[0-9]$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Automatically focus the next input if the current input is filled
      if (index < otp.length - 1) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    } else if (value === "") {
      // Handle backspace/delete action
      const newOtp = [...otp];
      newOtp[index] = ""; // Clear the current input
      setOtp(newOtp);

      // Automatically focus the previous input if not at the first input
      if (index > 0) {
        document.getElementById(`otp-input-${index - 1}`).focus();
      }
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const otpCode = otp.join("");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/verifyotp`,
        { phone, otp: otpCode },
        {
          headers: {
            Signature: process.env.REACT_APP_API_SIGNATURE,
          },
        }
      );
      if (response.status === 200) {
        setMessage("OTP verified successfully");
        toast.success("Admin Added Successfully")
        navigate("/admins");
      }
    } catch (error) {
      setMessage("Invalid OTP or OTP expired");
    }
  };

  const resendOtp = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/initiateadminverification`,
        { phone },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Signature: process.env.REACT_APP_API_SIGNATURE,
          },
        }
      );
      if (response.status === 200) {
        toast.success("OTP has been resent. Please check your phone.");
        setResendTimer(10); // Reset timer for 30 seconds
        setResendDisabled(true); // Disable resend button
      } else {
        toast.error("Failed to resend OTP.");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while resending OTP.");
    }
  };

  useEffect(() => {
    if (resendTimer > 0) {
      const timer = setInterval(() => {
        setResendTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timer); // Cleanup timer on unmount
    } else {
      setResendDisabled(false); // Re-enable resend button when timer reaches 0
    }
  }, [resendTimer]);

  return (
    <div className="authentication-wrapper authentication-cover">
      {/* /Logo */}
      <div className="authentication-inner row m-0">
        {/* Two Steps Verification */}
        <div className="d-flex authentication-bg position-relative py-sm-12 px-12 py-6">
          <div className="w-px-400 mx-auto pt-5 pt-lg-0">
            <h4 className="mb-1">Two Step Verification 💬</h4>
            <p className="text-start mb-6">
              We sent a verification code to your mobile. Enter the code from
              the mobile in the field below.
              {phone && (
                <span className="fw-medium d-block mt-1 h6">
                  ******{phone.slice(-4)}
                </span>
              )}
            </p>
            <p className="mb-0 fw-medium">Type your 6 digit security code</p>
            {message && <p className="text-danger">{message}</p>}
            <form id="twoStepsForm" onSubmit={handleSubmit}>
              <div className="mb-3">
                <div className="auth-input-wrapper d-flex align-items-center justify-content-between numeral-mask-wrapper">
                  {otp.map((digit, index) => (
                    <input
                      key={index}
                      type="tel"
                      id={`otp-input-${index}`}
                      className="form-control auth-input h-px-50 text-center numeral-mask mx-sm-1 my-2"
                      maxLength={1}
                      value={digit}
                      onChange={(e) => handleChange(e, index)}
                      autoFocus={index === 0}
                    />
                  ))}
                </div>
              </div>
              <button
                className="btn btn-primary d-grid w-100 mb-5"
                type="submit"
              >
                Verify my account
              </button>
              <div className="text-center">
                Didn't get code?
                {resendTimer > 0 ? (
                  <span style={{ color: "blue", padding: "2px" }}>Resend in {resendTimer}s</span>
                ) : (
                  <a href="javascript:void(0);" onClick={resendOtp}>Resend</a>
                )}
              </div>
            </form>
          </div>
        </div>
        {/* /Two Steps Verification */}
      </div>
    </div>
  );
}
