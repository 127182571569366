import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../../components/utils/loggedin';
import { toast } from 'react-toastify';

export default function AboutAdmin() {

  const { fetchAdminData, admin } = useAuth();
  console.log(admin)
  const { token } = useAuth()
  const [formData, setFormData] = useState({
    first_name: admin.first_name || '',
    last_name: admin.last_name || '',
    email: admin.email || '',
    phone: admin.phone || '',
    address: admin.address || '',
    city: admin.city || '',
    state: admin.state || '',
    zip: admin.zip || '',
    profile_img: null, // Initialize as null
  });

  useEffect(() => {
    setFormData(admin)
  }, [admin])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleFileChange = (e) => {
    setFormData(prevState => ({
      ...prevState,
      profile_img: e.target.files[0] || null
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    Object.keys(formData).forEach(key => {
      if (formData[key]) {
        formDataToSend.append(key, formData[key]);
      }
    });

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/admin/update/${admin.id}`,
        formDataToSend,
        {
          headers: {
            'Signature': process.env.REACT_APP_API_SIGNATURE,
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      fetchAdminData();
      // window.location.reload()
      toast.success("Super Admin Updated Successfully")
      console.log('Admin updated successfully:', response.data);
      document.getElementById('closeCanvas').click()
    } catch (error) {
      console.error('There was an error updating the admin:', error);
    }
  };


  return (
    <div className="card mb-6">
      <div className="card-body">
        <div className='d-flex justify-content-between align-items-center'>
          <small className="card-text text-uppercase text-muted small">About</small>
          {admin?.role?.permissions?.ADMIN_PANEL?.profile?.update_account &&
            <button
              className="btn btn-label-primary me-2"
              data-bs-target="#offcanvasAddUser"
              data-bs-toggle="offcanvas"
            >
              <i className="ri-pencil-line me-2"></i>
              Edit
            </button>
          }
        </div>
        <ul className="list-unstyled my-3 py-1">
          <li className="d-flex align-items-center mb-4">
            <i className="ri-user-3-line ri-24px" />
            <span className="fw-medium mx-2">Username:</span>
            <span>{admin.username}</span>
          </li>
          <li className="d-flex align-items-center mb-4">
            <i className="ri-user-3-line ri-24px" />
            <span className="fw-medium mx-2">Full Name:</span>
            <span className='text-capitalize'>{`${admin.first_name} ${admin.last_name}`}</span>
          </li>
          <li className="d-flex align-items-center mb-4">
            <i className="ri-check-line ri-24px" />
            <span className="fw-medium mx-2">Status:</span>
            <span>{admin.verified ? "Verified" : "Not Verified"}</span>
          </li>
          <li className="d-flex align-items-center mb-4">
            <i className="ri-star-smile-line ri-24px" />
            <span className="fw-medium mx-2">Role:</span>
            <span className="text-uppercase">{admin.admin_type}</span>
          </li>
          <li className="d-flex align-items-center mb-4">
            <i className="ri-flag-2-line ri-24px" />
            <span className="fw-medium mx-2">Address:</span>
            <span>{admin.city}, {admin.state}, {admin.zip}</span>
          </li>
        </ul>
        <small className="card-text text-uppercase text-muted small">Contacts</small>
        <ul className="list-unstyled my-3 py-1">
          <li className="d-flex align-items-center mb-4">
            <i className="ri-phone-line ri-24px" />
            <span className="fw-medium mx-2">Phone:</span>
            <span>{admin.phone}</span>
          </li>
          <li className="d-flex align-items-center mb-4">
            <i className="ri-mail-open-line ri-24px" />
            <span className="fw-medium mx-2">Email:</span>
            <span>{admin.email}</span>
          </li>
        </ul>
      </div>
      {/* Edit profile modal */}
      <div className="offcanvas offcanvas-end canvas-width editprofileofsuperadmin" tabIndex={-1} id="offcanvasAddUser" aria-labelledby="offcanvasAddUserLabel">
        <div className="offcanvas-header border-bottom">
          <h5 id="offcanvasAddUserLabel" className="offcanvas-title">Update Super Admin Profile</h5>
          <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
        </div>
        <div className="offcanvas-body mx-0 flex-grow-0 h-100">
          <form className="add-new-user pt-0" id="updateDoctorForm" onSubmit={handleSubmit}>
            {/* First Name and Last Name */}
            <div className="form-floating form-floating-outline mb-5">
              <input
                type="text"
                className="form-control"
                id="update-user-firstname"
                name="first_name"
                value={formData.first_name}
                onChange={handleChange}
              />
              <label htmlFor="update-user-firstname">First Name</label>
            </div>
            <div className="form-floating form-floating-outline mb-5">
              <input
                type="text"
                className="form-control"
                id="update-user-last_name"
                name="last_name"
                value={formData.last_name}
                onChange={handleChange}
              />
              <label htmlFor="update-user-last_name">Last Name</label>
            </div>
            {/* Email */}
            <div className="form-floating form-floating-outline mb-5">
              <input
                type="email"
                className="form-control"
                id="update-user-email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
              <label htmlFor="update-user-email">Email</label>
            </div>
            {/* Phone */}
            <div className="form-floating form-floating-outline mb-5">
              <input
                type="text"
                className="form-control"
                id="update-user-contact"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
              />
              <label htmlFor="update-user-contact">Phone</label>
            </div>
            {/* Address */}
            <div className="form-floating form-floating-outline mb-5">
              <textarea
                className="form-control editaddress"
                id="update-user-about"
                name="address"
                value={formData.address}
                onChange={handleChange}
                style={{ height: '100px' }} // Adjust the height as needed
              />
              <label htmlFor="update-user-address">Address</label>
            </div>


            {/* City */}
            <div className="form-floating form-floating-outline mb-5">
              <input
                type="text"
                className="form-control"
                id="update-user-city"
                name="city"
                value={formData.city}
                onChange={handleChange}
              />
              <label htmlFor="update-user-city">City</label>
            </div>
            {/* State */}
            <div className="form-floating form-floating-outline mb-5">
              <input
                type="text"
                className="form-control"
                id="update-user-state"
                name="state"
                value={formData.state}
                onChange={handleChange}
              />
              <label htmlFor="update-user-state">State</label>
            </div>
            {/* Zip */}
            <div className="form-floating form-floating-outline mb-5">
              <input
                type="text"
                className="form-control"
                id="update-user-zip"
                name="zip"
                value={formData.zip}
                onChange={handleChange}
              />
              <label htmlFor="update-user-zip">Zip</label>
            </div>
            {/* Profile Image */}
            {admin?.role?.permissions?.ADMIN_PANEL?.profile?.update_profile_img && <div className="form-floating form-floating-outline mb-5">
              <input
                type="file"
                className="form-control"
                id="update-user-profile-img"
                name="profile_img"
                onChange={handleFileChange}
              />
              <label htmlFor="update-user-profile-img">Profile Image</label>
            </div>}
            {/* Submit and Reset Buttons */}
            <button type="submit" className="btn btn-success me-sm-3 me-1">Update</button>
            <button type="reset" id="closeCanvas" className="btn btn-outline-secondary" data-bs-dismiss="offcanvas">Cancel</button>
          </form>
        </div>
      </div>
    </div >
  );
}
